import { IReqPayloadStructure } from 'src/types/store-types';
import {
  AutoSignInPayload,
  CheckAccountUniqueness,
  ForgotPassword,
  SendOTPCodeAgain,
  SignInPayload,
  SignUpPayload,
  UpdateablePersonalInfo,
  ValidatationCPF,
  ValidatationDNIPayload,
  ValidatationField,
  ValidatationUsername,
  ValidateEmailByOtp,
  ValidationOTP,
  VerifyEmailByOtp,
} from '../types/req-dto/authorization';
import { APP_API_VERSION } from '../utils/constants';
import { HttpClient } from './http';

const signInRequest = (payload: SignInPayload): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/authenticatePlayer`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};
const signInVerification = (params: {
  username: string;
  key: string;
  skinId: number;
}): Promise<IReqPayloadStructure> => {
  return HttpClient.get(
    {
      path: `${APP_API_VERSION}/verification?username=${params.username}&key=${params.key}&skinId=${params.skinId}`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    false
  );
};

const getExternalAppUrl = (payload: { token: string; authClientAppId: string }): Promise<any> => {
  return HttpClient.get(
    {
      path: `${APP_API_VERSION}/externalAppUrl?token=${payload.token}&authClientAppId=${payload.authClientAppId}`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    false
  );
};

//eslint-disable-next-line
const autoSignInRequest = (payload: AutoSignInPayload): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/authenticatePlayer`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};
//eslint-disable-next-line
const updatePersonalInfo = (payload: UpdateablePersonalInfo): Promise<IReqPayloadStructure> => {
  return HttpClient.patch(
    { path: `${APP_API_VERSION}/editPersonalInfo`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload
  );
};

const refreshTokenRequest = (): Promise<any> => {
  return HttpClient.get(
    { path: `${APP_API_VERSION}/refreshToken`, baseURL: process.env.REACT_APP_REMOTE as string },
    true
  );
};

const signUpRequest = (payload: SignUpPayload): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/playerRegistration`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const validateOTPRequest = (payload: ValidationOTP): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/validateOtp`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};
const validateEmailOTPRequest = (payload: ValidationOTP): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/validateEmailOtp`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    true
  );
};

const senOTPRequestAgain = (payload: SendOTPCodeAgain): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/resendOtp`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const sendEmailOTPRequestAgain = (payload: SendOTPCodeAgain): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/resendEmailOtp`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    true
  );
};

const validateCPFRequest = (payload: ValidatationCPF): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/validateCPF`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const validateNationalIdRequest = (payload: ValidatationField): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/validateRegistrationField`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const validateUsernameRequest = (payload: ValidatationUsername): Promise<any> => {
  return HttpClient.get(
    { path: `${APP_API_VERSION}/checkUsername`, baseURL: process.env.REACT_APP_REMOTE as string },
    false,
    payload
  );
};

const forgotPasswordRequest = async (payload: ForgotPassword): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/resetPassword`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const emailVerififcation = (payload: { key: string }): Promise<any> => {
  return HttpClient.get(
    { path: `${APP_API_VERSION}/confirmEmail`, baseURL: process.env.REACT_APP_REMOTE as string },
    false,
    payload
  );
};

const validatationDNIRequest = (payload: ValidatationDNIPayload): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/validateNationalId`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const verifyEmailByOtpRequest = (payload: VerifyEmailByOtp): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/verifyEmailByOtp`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const validateEmailByOtpRequest = (payload: ValidateEmailByOtp): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/validateEmailByOtp`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const resendEmailOtpRequest = (payload: VerifyEmailByOtp): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/resendEmailOtp`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const checkAccountUniquenessRequest = (payload: CheckAccountUniqueness): Promise<IReqPayloadStructure> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/checkAccountUniqueness`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

export {
  autoSignInRequest,
  checkAccountUniquenessRequest,
  emailVerififcation,
  forgotPasswordRequest,
  getExternalAppUrl,
  refreshTokenRequest,
  resendEmailOtpRequest,
  sendEmailOTPRequestAgain,
  senOTPRequestAgain,
  signInRequest,
  signInVerification,
  signUpRequest,
  updatePersonalInfo,
  validatationDNIRequest,
  validateCPFRequest,
  validateEmailByOtpRequest,
  validateEmailOTPRequest,
  validateNationalIdRequest,
  validateOTPRequest,
  validateUsernameRequest,
  verifyEmailByOtpRequest,
};
