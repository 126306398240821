import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { setCookies } from 'src/store/app/actions';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';
// import './styles.scss';

interface IProps {
  data?: any;
}
const CookiesPopup: FC<IProps> = () => {
  const { t }: Translation = useTranslation();

  const { cookiesAccepted } = useSelector((state: RootState) => state.app);

  setBodyOverflow('unset');

  const _closeDialog = useConnectedAction(closeDialog);
  const _setCookies = useConnectedAction(setCookies);

  const removeDialog = (): void => {
    setBodyOverflow('set');
    _closeDialog({ dialogType: EnumDialogsKeys.COOKIES_POPUP });
  };

  const handleCookiesAccept = (): void => {
    _setCookies();
    setBodyOverflow('set');
    _closeDialog({ dialogType: EnumDialogsKeys.COOKIES_POPUP });
  };

  return (
    <PopUpContainer
      isOpen={!Boolean(cookiesAccepted)}
      handleOK={handleCookiesAccept}
      handleCancel={removeDialog}
      hasOverlay
      title={t('acceptCookies')}
    />
  );
};

export default CookiesPopup;
