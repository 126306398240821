import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';

interface IProps {
  data?: any;
}
const BadGeoLocationPopup: FC<IProps> = ({ data }) => {
  const { t }: Translation = useTranslation();
  const { country, city } = data || {};

  setBodyOverflow('unset');

  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    setBodyOverflow('set');
    _closeDialog({ dialogType: EnumDialogsKeys.BAD_GEOLOCATION });
  };

  return <PopUpContainer isOpen handleOK={removeDialog} hasOverlay title={t('geolocationTxt', { country, city })} />;
};

export default BadGeoLocationPopup;
