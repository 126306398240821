import { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import { initSdkLegitimuzRequest } from '../../../services/userApi';

type IProps = {
  name: string;
  value: string;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
};
const Ip_Input: FC<IProps> = (props) => {
  // Hooks
  const { t }: Translation = useTranslation();

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const resetSignUpError = useResetSignUpError();

  // Selectors
  const { errors: signUpErrors, cpfValue } = useSelector((state: RootState) => state.user);
  // YUP Validation
  const validationSchema = Yup.object().shape({
    ip: Yup.string().required(t('requiredField')).matches(new RegExp(props.regexp), t('fieldLength')),
  });

  const updateValue = useCallback(
    (ipAddress: string) => {
      handleValidate(ipAddress);
      props.onChange({
        target: {
          name: props.name,
          value: ipAddress,
        },
      });
    },
    [props]
  );

  useEffect(() => {
    const initSdk = async (): Promise<void> => {
      const data = {
        meta: { cpf: cpfValue },
        deviceinfo: navigator.userAgent,
        hardware_concurrency: navigator.hardwareConcurrency,
      };
      initSdkLegitimuzRequest(data)
        .then((response) => {
          if (response?.body) {
            const { ip_address } = response?.body;
            updateValue(ip_address);
          }
        })
        .catch((error) => {
          console.error('Fraud Prevention Error:', error);
          updateValue('');
        });
    };

    if (cpfValue) initSdk();
  }, [cpfValue]);

  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ ip: value }, { abortEarly: false });
      resetSignUpError('ip');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, ip: error.errors[0] });
      }
    }
  };

  // Actions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (signUpErrors.ip) {
      resetSignUpError('ip');
    }
    props.onChange(e);
  };

  const handleBlur = (): void => {
    handleValidate(props.value?.trim());
  };

  return <Input error={signUpErrors.ip} {...props} onChange={handleOnChange} onBlur={handleBlur} />;
};
export default Ip_Input;
