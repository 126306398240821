/* eslint-disable sonarjs/cognitive-complexity */
import cn from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Chat from 'src/components/base-components/Chat/Chat';
import { SvgIcon } from 'src/components/common/SvgIcon';
import Tooltip from 'src/components/common/Tooltip';
import { scrollToTop, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { getMobileFixedMenuData } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import {
  resetSectionLoading,
  setGameBoxWidgetOpen,
  setSectionLoading,
  setSelectedCategory,
  setSelectedFooterMenuItem,
  setShowUserSettings,
} from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys, EnumStatusesOfKyc } from 'src/store/dialog/types';
import { setSelectedGame, setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import {
  setMobileLiveChatVisibility,
  toggleMobileMenu,
  toggleMobileProvider,
  toggleMobileSearch,
} from 'src/store/mobile/actions';
import { MobileFixedMenuItem } from 'src/types/res-dto/mobile-res';
import { RootState } from 'src/types/store-types';
import { MediaManager } from '../../../../manager/MediaManager';
import _styles from './styles.module.scss';

const MobileFixedMenu: FC = () => {
  const { t }: Translation = useTranslation();

  const navigate = useNavigate();

  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _toggleMobileSearch = useConnectedAction(toggleMobileSearch);
  const _toggleMobileProvider = useConnectedAction(toggleMobileProvider);
  const _setSelectedFooterMenuItem = useConnectedAction(setSelectedFooterMenuItem);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);
  const _toggleMobileMenu = useConnectedAction(toggleMobileMenu);
  const _setShowUserSettings = useConnectedAction(setShowUserSettings);
  const _setGameBoxWidgetOpen = useConnectedAction(setGameBoxWidgetOpen);
  const _setMobileLiveChatVisibility = useConnectedAction(setMobileLiveChatVisibility);
  const _selectedGame = useConnectedAction(setSelectedGame);
  const _resetSectionLoading = useConnectedAction(resetSectionLoading);
  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setSectionLoading = useConnectedAction(setSectionLoading);
  const _openDialog = useConnectedAction(openDialog);

  const { showMenu, showSearch, showProviders } = useSelector((state: RootState) => state.mobile);
  const { selectedCategory, selectedFooterMenu, showUserSetting, isGameBoxOpen } = useSelector(
    (state: RootState) => state.app
  );
  const { kycStatus, user } = useSelector((state: RootState) => state.user);

  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const iframe = document?.getElementById('gamebox-widget') as HTMLIFrameElement;

  const [menuItems, setMenuItems] = useState<MobileFixedMenuItem[]>([]);
  const [showBurgerMenu, setShowBurgerMenu] = useState<boolean>(false);

  const selectCategory = (category: MobileFixedMenuItem): void => {
    _resetSectionLoading();

    if (iframe && isGameBoxOpen) {
      iframe?.contentWindow?.postMessage(
        {
          type: 'CHANGE_WIDGET_VIEW',
          view: '',
        },
        '*'
      );
      _setGameBoxWidgetOpen();
    }

    if (category.needLogin && !user) {
      _openAuthModal('login');
      _setSectionLoading(false);
      setBodyOverflow('unset');
    } else {
      if (category.type === 'game') {
        if (
          user &&
          process.env.REACT_APP_KYC_ENABLE === 'true' &&
          (kycStatus === EnumStatusesOfKyc.PENDING ||
            kycStatus === EnumStatusesOfKyc.EXPIRED ||
            kycStatus === EnumStatusesOfKyc.REJECTED ||
            kycStatus === undefined)
        ) {
          _openDialog({
            dialogType: EnumDialogsKeys.KYC_POPUP,
            dialogProps: { data: {} },
          });
        } else {
          _selectedGame({ id: category?.gameId });
        }
      } else if (category.type === 'content') {
        navigate(`/content/${category.name}`);
        _setSelectedCategory(category.name);
      } else {
        const selectedCategory = category.name === 'sport' ? 'betting' : category.name;
        _setSelectedCategory(selectedCategory);
        _setSelectedProvider(['all_providers']);
        _setSelectedTag(category.hasHomeTag ? 'home' : 'allGames');
        navigate(`/category/${selectedCategory}`);
      }
    }
  };

  const selectMenuItem = (menuItem: any): void => {
    if (menuItem.type === 'chat') {
      _setSelectedFooterMenuItem('');
      if (['liveChatButton', 'liveChat'].includes(generalConfigs?.chat?.type)) {
        _setMobileLiveChatVisibility(true);
      } else if (generalConfigs.chat.type === 'tawkChat') {
        (window as any)?.Tawk_API.maximize();
      } else {
        (window as any)?.zE('messenger', 'open');
      }
    } else if (menuItem.type === 'burgerMenu') {
      setShowBurgerMenu(true);
    } else {
      _setSelectedFooterMenuItem(menuItem.name);
      selectCategory(menuItem);
      setShowBurgerMenu(false);
    }

    showUserSetting && _setShowUserSettings();
    showMenu && _toggleMobileMenu();
    showProviders && _toggleMobileProvider();
    showSearch && _toggleMobileSearch();

    menuItem.type !== 'burgerMenu' && scrollToTop();
  };

  const getMobileFixedMenu = async (): Promise<void> => {
    let res: MobileFixedMenuItem[] | any = null;

    if (PersisterService.hasPersistValue('mobiledFixedMenu')) {
      res = PersisterService.getPersistValue('mobiledFixedMenu');
      setMenuItems(res);
    } else {
      res = await getMobileFixedMenuData();

      if (res.success) {
        PersisterService.setPersistValue('mobiledFixedMenu', res.result);
        setMenuItems(res.result);
      }
    }
  };

  useEffect(() => {
    getMobileFixedMenu().catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    !showMenu && _setSelectedFooterMenuItem('');
    showMenu && setShowBurgerMenu(false);
  }, [showMenu, showBurgerMenu]);

  useEffect(() => {
    _setSelectedFooterMenuItem(
      Boolean(menuItems?.filter((f) => f.name === selectedCategory).length) ? selectedCategory : null
    );
  }, [selectedCategory, selectedFooterMenu, menuItems]);

  const renderMenuItem = (menuItem: { imageSrcName: string }): JSX.Element => {
    if (generalConfigs?.isVertical) {
      return (
        <img
          src={MediaManager.getSrcFromMediaAssets(`${menuItem.imageSrcName}.png`, '/categoryIcons/')}
          alt={menuItem.imageSrcName}
        />
      );
    }

    if (
      [
        'silvaSeries',
        'casinoTournament',
        'tigerFortune',
        'poker',
        'fun-mission',
        'race',
        'bingo',
        'cybersport',
        'liveSport',
        'pGsoft',
      ].includes(menuItem.imageSrcName)
    ) {
      return <SvgIcon src={`/images/navigation-icons/${menuItem.imageSrcName}.svg`} />;
    }

    return <span className={`icon-${menuItem.imageSrcName}`}></span>;
  };

  const menuItemButton = (menuItem: any, type = ''): JSX.Element => {
    return (
      <button key={menuItem.name} className={_styles.element} onClick={() => selectMenuItem(menuItem)}>
        {renderMenuItem(menuItem)}
        {t(`category_${menuItem.name}${menuItem.type === 'game' ? 'game' : ''}`)}
        {selectedFooterMenu === menuItem.name && type !== 'burgerMenu' && <span className={_styles.active} />}
      </button>
    );
  };

  return (
    <div
      className={cn(_styles.mobile_fixed_menu_container, 'mobile_fixed_menu_container', {
        [_styles.hide_fixed_menu]: selectedCategory === 'betting' && process.env.REACT_APP_HIDE_SPORT === 'true',
      })}
    >
      {process.env.REACT_APP_CLUBAPP_MODE && (
        <div className={_styles.btn_container}>
          <button
            key={'club'}
            className={`${_styles.element} ${_styles.clubAppBtn}`}
            onClick={() => (window as any)?.close()}
          >
            <SvgIcon src={`/images/navigation-icons/backspace-solid.svg`} />
            {t(`category_backToClub`)}
          </button>
        </div>
      )}
      {menuItems.length > 0 &&
        menuItems?.map((menuItem: MobileFixedMenuItem, i: number) => {
          return (
            <div
              className={cn(_styles.btn_container, {
                [_styles.fixed_menu_icon_content]: generalConfigs?.isVertical,
              })}
              key={menuItem.name + i}
            >
              {menuItem.type === 'burgerMenu' ? (
                <Tooltip
                  data={menuItem?.burgerMenu.map((item: any) => menuItemButton(item, menuItem.type))}
                  position={'top'}
                  className={'burgerMenu'}
                  showBurgerMenu={showBurgerMenu}
                  setShowBurgerMenu={setShowBurgerMenu}
                >
                  {menuItemButton(menuItem, menuItem.type)}
                </Tooltip>
              ) : (
                menuItemButton(menuItem)
              )}
            </div>
          );
        })}
      {!!generalConfigs?.chat && <Chat configs={generalConfigs?.chat} placement="mobileFixedMenu" />}
    </div>
  );
};
export default MobileFixedMenu;
