import { getSkinId } from 'src/helpers/utils';
import { GameParamType } from 'src/types/req-dto/game';
import { GameResType } from 'src/types/res-dto/games';
import HttpClient from './http';

const gameUrlRequest = (payload: GameParamType): Promise<GameResType> => {
  const device = payload.device === 'mobile' ? 'mobile' : 'desktop';
  const data: any = { ...payload, device };
  if (!payload.userId) {
    delete data.token;
  }
  if (payload.lang === 'ar') {
    data.lang = 'es-ar';
  }
  return HttpClient.get(
    { path: `/lobby/${payload.userId ? 'gameUrl' : 'publicGameUrl'}`, baseURL: process.env.REACT_APP_REMOTE as string },
    Boolean(payload.userId),
    data
  );
};

const getCategoriresData = (device: string, limit = 25): Promise<GameResType> => {
  const skinId = getSkinId();
  return HttpClient.get(
    {
      path: `/lobbyV2/getLobbyStructure?skinId=${skinId}&status=ok&device=${device}&limit=${limit}`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    false
  );
};

// get last played 20 games
const getLastPlayedGamesData = (): Promise<GameResType> => {
  return HttpClient.get(
    {
      path: `/api/getRecentGames`,
      baseURL: process.env.REACT_APP_REPORTS as string,
    },
    true
  );
};

const gameListRequest = (
  device: string,
  providers: string[],
  offset: number,
  selectedCategory: string,
  selectedTag: string[] | null,
  gamesCount: number | null
): Promise<GameResType> => {
  let searchParams = '';
  const skinId = getSkinId();

  if (selectedCategory !== 'none') searchParams += `categories[]=${selectedCategory}&`;
  if (offset) searchParams += `offset=${offset}&`;

  const _ = Array.isArray(providers) ? providers?.filter((f: string) => !['all_providers', ''].includes(f)) ?? [] : [];

  if (_.length > 0) {
    _.map((m: string) => (searchParams += `providerNames[]=${m}&`));
  }

  if (selectedTag && !['allGames', ''].includes(selectedTag[0])) searchParams += `tags[]=${selectedTag}&`;

  return HttpClient.get(
    {
      path: `/lobbyV2/getGamesList?${searchParams}&limit=${
        gamesCount ?? 0
      }&skinId=${skinId}&status=ok&device=${device}`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    false
  );
};

const seacrhByGameNameRequest = (
  device: string,
  gameName: string,
  providers: string[] | null,
  selectedTag: string[] | null,
  selectedCategory: string
): Promise<GameResType> => {
  let searchParams = `namePart=${encodeURIComponent(gameName)}`;
  const skinId = getSkinId();

  if (selectedCategory) searchParams += `&categories[]=${selectedCategory}&`;

  const _ = providers?.filter((f: string) => f !== 'all_providers') ?? [];

  if (_.length > 0) {
    _.map((m: string) => (searchParams += `providerNames[]=${m}&`));
  }

  if (selectedTag && !['allGames', 'home', 'free_rounds'].includes(selectedTag[0]))
    searchParams += `&tags[]=${selectedTag}`;

  return HttpClient.get(
    {
      path: `/lobbyV2/searchGames?${searchParams}&skinId=${skinId}&status=ok&device=${device}`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    false
  );
};

const getMessages = (accountId: string): Promise<GameResType> => {
  const skinId = getSkinId();
  return HttpClient.get({
    path: `api/message?skinId=${skinId}&accountId=${accountId}`,
    baseURL: process.env.REACT_APP_MESSAGES_URL as string,
  });
};

export {
  gameListRequest,
  gameUrlRequest,
  getCategoriresData,
  getLastPlayedGamesData,
  getMessages,
  seacrhByGameNameRequest,
};
