import cn from 'classnames';
import { useFormik } from 'formik';
import { FC, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { Input } from 'src/components/common/Input';
import { getSkinId } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import PersisterService from 'src/services/PersisterService';
import { changePasswordRequest, checkOldPasswordRequest } from 'src/services/userApi';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';
import { getChangePasswordFormValidation } from './changePasswordValidationScheme';
import _styles from './styles.module.scss';

interface IForm {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}
const ChangePassword: FC = () => {
  const { t }: Translation = useTranslation();
  const skinId = getSkinId();

  const _openDialog = useConnectedAction(openDialog);

  const { user } = useSelector((state: RootState) => state.user);

  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [rule, setRule] = useState<number[]>([6, 16]);

  const passwordRegExp = useMemo(() => {
    let passwordConfig = [];
    if (PersisterService.hasPersistValue('authConfigs')) {
      passwordConfig = PersisterService.getPersistValue('authConfigs')?.[2]?.registrationSteps.filter((m: any) => {
        if (Object.keys(m).includes('password')) {
          return m;
        }
      });
    }

    if (!!passwordConfig?.[0]) {
      const _ = passwordConfig[0].password.regexp;
      if (_) {
        const match = _.match(/\{(\d+),(\d+)\}/);
        const minLength = parseInt(match[1]);
        const maxLength = parseInt(match[2]);
        setRule([minLength, maxLength]);
        return passwordConfig[0].password.regexp;
      }
    }

    return '^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9~_+^#$@!%*?&]+){6,16}$';
  }, []);

  const changePasswordReq = (): void => {
    changePasswordRequest({ accountId: user.id, password: values.newPassword })
      .then((changePassRes) => {
        if (changePassRes?.success) {
          _openDialog({
            dialogType: EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS,
            dialogProps: { dialogCase: 'changePassword' },
          });

          handleReset(event);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setBtnLoading(false));
  };

  const formik = useFormik<IForm>({
    validationSchema: getChangePasswordFormValidation(t, passwordRegExp),
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
    },
    onSubmit: (values) => {
      setBtnLoading(true);

      const checkOldPasswordParams = {
        username: user.username,
        password: values.oldPassword,
        loginFromBC: 1,
        skinId: skinId,
      };

      checkOldPasswordRequest(checkOldPasswordParams)
        .then((res) => {
          if (res?.success) {
            localStorage.setItem('user', JSON.stringify(res.result));
            if (process.env.REACT_APP_KYC_ENABLE === 'true') {
              const sdkInstance = (window as any)?.Legitimuz({
                token: '5cb851a5-fb77-4474-95ca-1780a5fc3bab',
                host: 'https://api.legitimuz.com',
                onSuccess: changePasswordReq,
              });
              sdkInstance.mount();
              if (user?.personalInfo.kyc?.verificationStatus === 'verified') {
                sdkInstance.verifyDocument({ cpf: user?.personalInfo.nationalId });
              } else {
                sdkInstance.startFaceIndex({ cpf: user?.personalInfo.nationalId });
              }
            } else {
              changePasswordReq();
            }
          } else {
            setFieldError('oldPassword', t('passwordIsWrong'));
          }
        })
        .catch(() => {
          setFieldError('oldPassword', t('passwordIsWrong'));
        })
        .finally(() => setBtnLoading(false));
    },
  });

  const { values, getFieldProps, handleSubmit, errors, touched, setFieldError, handleReset } = formik;

  return (
    <div className={_styles.container}>
      <div className={_styles.content}>
        <div>
          <div className={_styles.container__item}>
            <Input
              {...getFieldProps('oldPassword')}
              type="password"
              value={values.oldPassword}
              label={t('oldPassword')}
              error={(!!touched.oldPassword && !!errors.oldPassword && errors.oldPassword) || ''}
              className={_styles.container__item_input}
              inputHeight={40}
              iconPosition={'right'}
            />
          </div>
          <div className={_styles.container__item}>
            <Input
              {...getFieldProps('newPassword')}
              type="password"
              value={values.newPassword}
              label={t('newPassword')}
              error={(!!touched.newPassword && !!errors.newPassword && errors.newPassword) || ''}
              className={_styles.container__item_input}
              inputHeight={40}
              iconPosition={'right'}
            />
          </div>
          <div className={_styles.container__item}>
            <Input
              {...getFieldProps('repeatPassword')}
              type="password"
              value={values.repeatPassword}
              label={t('repeatPassword')}
              error={(!!touched.repeatPassword && !!errors.repeatPassword && errors.repeatPassword) || ''}
              className={_styles.container__item_input}
              inputHeight={40}
              iconPosition={'right'}
            />
          </div>
          <div className={_styles.container__item}>
            <Button
              className={cn(_styles.container__item_button, 'success-filled-btn', { [_styles.disabled]: btnLoading })}
              variant={'text'}
              loading={btnLoading}
              onClick={handleSubmit}
            >
              {t('savePassword')}
            </Button>
          </div>
        </div>
        {!isMobileOnly && (
          <div className={_styles.note}>
            <p>{t('your_password_must')}:</p>
            <ul>
              <li>{t('between_6_to_12', { min: rule[0], max: rule[1] })}</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
export default ChangePassword;
