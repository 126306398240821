import React, { ChangeEvent, FC, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components//common/Button';
import { Input } from 'src/components/common/Input';
import PopUpContainer from 'src/components/common/modal/PopUp';
import RadioInput from 'src/components/common/RadioInput';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeAccountRequest } from 'src/services/userApi';
import { closeAccountModal } from 'src/store/account-modal/actions';
import { EnumClosureType } from 'src/store/dialog/types';
import { setLogout } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

const ClosureAccount: FC = () => {
  const { t } = useTranslation();

  const { personalInfo } = useSelector((state: RootState) => state.user?.user || {});

  const [selectedAction, setSelectedAction] = useState<string>(t('permanent'));
  const [actionType, setActionType] = useState<string>(EnumClosureType.PERMANENT);
  const [durationInHours, setDurationInHours] = useState<number | null>(null);
  const [showKYCService, setShowKYCService] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const _logout = useConnectedAction(setLogout);
  const _closeModal = useConnectedAction(closeAccountModal);

  const calculateDurationInHours = (duration: string): number | null => {
    switch (duration) {
      case t('twentyFourHours'):
        return 24;
      case t('oneWeek'):
        return 7 * 24;
      case t('oneMonth'):
        return 30 * 24;
      default:
        return +duration;
    }
  };

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>, key = ''): void => {
    const { value } = e.target;

    setSelectedAction(value);
    setError(false);
    if (key === EnumClosureType.PERMANENT || key === EnumClosureType.CLOSE) {
      setActionType(key === EnumClosureType.PERMANENT ? EnumClosureType.PERMANENT : EnumClosureType.CLOSE);
      setDurationInHours(null);
      setShowKYCService(true);
    } else {
      setShowKYCService(false);
      setActionType(EnumClosureType.TEMPORARY);
      setDurationInHours(calculateDurationInHours(value));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const isValid = /^(?:[1-9]\d{0,2}|100[0-8])$/.test(value);
    setError(!isValid);
    setShowKYCService(false);
    setActionType('temporary');
    setDurationInHours(calculateDurationInHours(value));
    setSelectedAction(value);
  };

  const handleSubmit = (): void => {
    const data = {
      closureType: actionType,
      closureDuration: durationInHours,
    };
    closeAccountRequest(data)
      .then((response) => {
        if (response.success) {
          setIsPopUpOpen(true);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleKYCSubmit = async (): Promise<void> => {
    const sdkInstance = (window as any)?.Legitimuz({
      token: '5cb851a5-fb77-4474-95ca-1780a5fc3bab',
      host: 'https://api.legitimuz.com',
      onSuccess: handleSubmit,
    });
    sdkInstance.mount();
    if (personalInfo.kyc?.verificationStatus === 'verified') {
      sdkInstance.startFaceIndex({ cpf: personalInfo.nationalId });
    } else {
      sdkInstance.verifyDocument({ cpf: personalInfo.nationalId });
    }
  };

  const closePopUp = (): void => {
    setIsPopUpOpen(false);
    _closeModal();
    if (isMobileOnly) {
      setBodyOverflow('set');
    }
    _logout();
  };

  return (
    <div className="closure_container">
      <p className="closure_txt">{t('account_closure_txt')}</p>
      <div className="closureRow">
        <div>
          <RadioInput
            data={[t('permanent')]}
            onChange={(e) => handleRadioChange(e, EnumClosureType.PERMANENT)}
            value={selectedAction}
            name="duration"
            descriptions={[t('permanentDsc')]}
          />
          <RadioInput
            data={[t('close_account')]}
            onChange={(e) => handleRadioChange(e, EnumClosureType.CLOSE)}
            value={selectedAction}
            name="duration"
            descriptions={[t('close_accountDsc')]}
          />
        </div>
        <div>
          <RadioInput
            data={[t('twentyFourHours'), t('oneWeek'), t('oneMonth')]}
            onChange={handleRadioChange}
            value={selectedAction}
            name="duration"
          />
          <Input
            onChange={handleInputChange}
            placeholder={t('closure_manual_inp')}
            value={selectedAction}
            name="duration"
            type="number"
            className="closure_input"
          />
        </div>
      </div>
      <p className="error_msg">{(error || +selectedAction <= 0) && t('closure_error_msg')}</p>
      {showKYCService ? (
        <Button disabled={error || +selectedAction <= 0} onClick={handleKYCSubmit}>
          {t('verifyCloseAccount')}
        </Button>
      ) : (
        <Button disabled={error || +selectedAction <= 0} onClick={handleSubmit}>
          {t('submit')}
        </Button>
      )}
      {isPopUpOpen && (
        <PopUpContainer
          isOpen={isPopUpOpen}
          hasOverlay
          icon={<SvgIcon src={icons.success} />}
          handleOK={closePopUp}
          handleClose={closePopUp}
          type="small"
          description={t('user_account_disabled')}
          zIndex={180}
        />
      )}
    </div>
  );
};

export default ClosureAccount;
