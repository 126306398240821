import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Dropdown from 'src/components/common/Dropdown/Dropdown';
import { Typography } from 'src/components/common/Typography';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError, setPhoneCode } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import { COUNTRY_LIST } from 'src/utils/constants';
import * as Yup from 'yup';
import _styles from './styles.module.scss';

export type Country = {
  label: string;
  value: string;
  iconSrc: string;
  code: string | null;
};

type Props = {
  onChange: (e: any) => void;
  name: string;
  value: string;
  required: boolean;
  label: boolean;
  tabIndex: number;
  specialCountries?: [];
};
const Country_Select: FC<Props> = ({ onChange, name, value, required, label, specialCountries, tabIndex }) => {
  const { t }: Translation = useTranslation();

  const _setPhoneCode = useConnectedAction(setPhoneCode);
  const _setErrorReq = useConnectedAction(setError);

  const resetSignUpError = useResetSignUpError();

  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const [countryVal, setCountryVal] = useState<Country | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [countries, setCountries] = useState<any[]>([]);

  const validationSchema = Yup.object().shape({
    country: Yup.string().required(t('requiredField')),
  });

  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ country: value }, { abortEarly: false });
      resetSignUpError('country');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, country: error.errors[0] });
      }
    }
  };

  const handleSelect = (option: any): void => {
    onChange({ target: { name, value: option.value } });
    setCountryVal(option);
    _setPhoneCode(option.code);
    handleValidate(option.value);
  };

  useEffect(() => {
    !value && isDropdownOpen && handleValidate(value);
  }, [isDropdownOpen]);

  useEffect(() => {
    if (!countryVal && generalConfigs?.defaultCountry) {
      const defaultSelected: Country | undefined = COUNTRY_LIST.find(
        (c: Country) => c.value === generalConfigs.defaultCountry
      );
      if (defaultSelected) {
        handleSelect(defaultSelected);
      }
    }
  }, [countryVal, generalConfigs]);

  useEffect(() => {
    setCountries(specialCountries ? specialCountries : COUNTRY_LIST);
  }, [specialCountries]);

  useEffect(() => {
    if (countries.length === 1) {
      handleSelect(countries[0]);
    }
  }, [countries]);

  return (
    <div className={_styles.country_select__wrapper} style={{ marginTop: countries.length === 1 ? 0 : '35px' }}>
      {label && (
        <Typography className={_styles.label} variant={'body5'}>
          {t(label)} {required && <span>*</span>}
        </Typography>
      )}
      <Dropdown
        withImg
        list={countries}
        selected={countryVal}
        setIsDropdownOpen={setIsDropdownOpen}
        handleSelect={handleSelect}
        className={_styles.country_select}
        listClassName={_styles.country_select__list}
        tabIndex={tabIndex}
      />
      <span className={_styles.error}>{signUpErrors.country}</span>
    </div>
  );
};

export default Country_Select;
