import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { getSkinId } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useDebounce } from 'src/hooks/use-debounce';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { checkAccountUniquenessRequest, validatationDNIRequest } from 'src/services/authorizationApi';
import { setDniResult, setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import { icons } from '../../../configs/icons';
import Dropdown from '../Dropdown';
import _styles from '../Employment_Select/styles.module.scss';
import PopUpContainer from '../modal/PopUp';
import { SvgIcon } from '../SvgIcon';
import { Typography } from '../Typography';

const INIT_VALUES = { dni: '', idMainIssue: '', gender: 'male' };
const INIT_ERRORS = { dni: '', idMainIssue: '', gender: '' };

type IProps = {
  name: string;
  value: any;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required: boolean;
  idMainIssueValue: string;
  dniValue: string;
};
const DNI_Input: FC<IProps> = (props) => {
  // Hooks
  const { t }: Translation = useTranslation();
  const skinId = getSkinId();

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const _setDniResult = useConnectedAction(setDniResult);

  const resetSignUpError = useResetSignUpError();

  // Selectors
  const { errors: signUpErrors, dniResult } = useSelector((state: RootState) => state.user);
  const { province, gender } = dniResult || {};
  // States
  const [values, setValues] = useState<any>(INIT_VALUES);
  const [errors, setErrors] = useState<any>(INIT_ERRORS);
  const [dniLoading, setDnaLoading] = useState<boolean>(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const [errorDniText, setErrorDniText] = useState<string>('');

  const debouncedDni: any = useDebounce(values.dni, 500);
  const debouncedIdMainIssue: any = useDebounce(values.idMainIssue, 500);

  // Constants
  const dniRegExp = useMemo(() => {
    return props.regexp ? new RegExp(props.regexp) : new RegExp('^[0-9+]*$');
  }, [props.regexp]);

  const checkDniUniqueness = (dni: string): void => {
    checkAccountUniquenessRequest({ skinId, key: 'nationalId', value: dni })
      .then(({ success, result }) => {
        if (!success) {
          console.error('Failed to check account uniqueness.');
          return;
        }
        const { isExisting } = result || {};
        if (isExisting) {
          setGlobalError('dni', t('dniUniqueness'));
        }
      })
      .catch((error) => {
        console.error('Error checking account uniqueness:', error);
      });
  };

  // API Calls
  const validateDniReq = (payload: any): void => {
    setDnaLoading(true);
    validatationDNIRequest(payload)
      .then((res) => {
        if (res.success) {
          reSetGlobalError();
          _setDniResult(res.result);
          checkDniUniqueness(payload.nationalId);
          props.onChange({ target: { name: 'dni', value: values } });
        } else {
          const error = res?.message;
          if (['unprocessable_entity'].includes(error?.key) && error?.params?.length > 1) {
            const errorType = error.params[0];
            const locationDetails = error.params[1];
            if (errorType === 'prohibitedLocation') {
              const { currentLocation, allowedLocations } = locationDetails;
              const allowedLocationsString = allowedLocations.join(', ');
              setGlobalError(
                'dni',
                t('locationNotAllowed', {
                  currentLocation: currentLocation,
                  allowedLocations: allowedLocationsString,
                })
              );
            }
          } else {
            setGlobalError('dni', t('invalid_national_id'));
          }
          _setDniResult({});
        }
      })
      .catch(() => {
        setGlobalError('dni', t('invalid_national_id'));
      })
      .finally(() => {
        setDnaLoading(false);
      });
  };

  // YUP Validation
  const validationSchema = Yup.object().shape({
    dni: Yup.string()
      .trim()
      .required(t('requiredField'))
      .matches(dniRegExp, t('invalid_dni'))
      .length(8, t('filedInvalidLength', { length: 8 })),
    idMainIssue: Yup.string()
      .trim()
      .required(t('requiredField'))
      .length(11, t('filedInvalidLength', { length: 11 })),
    gender: Yup.string().required(t('requiredField')),
  });

  const handleValidate = async (key: string, value: string): Promise<void> => {
    try {
      await validationSchema.validateAt(key, { [key]: value });
      resetError(key);
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        setGlobalError(key, error.errors[0]);
      }
    }
  };

  // Actions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (props.value) {
      props.onChange({ target: { name: 'dni', value: '' } });
    }
    setValues((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    handleValidate(e.target.name, e.target.value);
  };

  const handleSelect = (e: any): void => {
    if (props.value) {
      props.onChange({ target: { name: 'dni', value: '' } });
    }
    reSetGlobalError();
    setValues((prev: any) => ({ ...prev, gender: e.value }));
    handleValidate('gender', e.value);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>): void => {
    handleValidate(e.target.name, e.target.value);
  };

  const setGlobalError = (key: string, message: string): void => {
    _setErrorReq({ ...signUpErrors, dni: message });
    setErrors((prev: any) => ({ ...prev, [key]: message }));
  };

  const reSetGlobalError = (): void => {
    setErrors(INIT_ERRORS);
    resetSignUpError('dni');
  };

  const resetError = (key: string): void => {
    const newErrors = errors;
    delete newErrors[key];
    setErrors(newErrors);
  };

  const getGenderCode = (values: any): string => {
    const genderMap: any = {
      male: 'M',
      female: 'F',
    };

    return genderMap[values.gender];
  };

  // Effects
  useEffect(() => {
    if (
      values.dni.length === 8 &&
      values.gender &&
      values.idMainIssue?.length === 11 &&
      !Object.keys(errors).find((el: string) => errors[el]) &&
      !props.value
    ) {
      const payload = {
        nationalId: values.dni,
        skinId: skinId,
        gender: getGenderCode(values),
        idMainIssue: values.idMainIssue,
        nationalIdType: 'DNI',
      };
      validateDniReq(payload);
    }
  }, [debouncedDni, debouncedIdMainIssue, values.gender, values.dni, values.idMainIssue, props.value, errors]);

  useEffect(() => {
    if (props?.value && Object?.keys(props?.value)?.length) {
      setValues({ dni: props.value?.dni, gender: props.value?.gender, idMainIssue: props.value?.idMainIssue });
    }
  }, [props.value]);

  useEffect(() => {
    if (Object.keys(dniResult).length) {
      if (province !== 'Buenos Aires') {
        setIsPopUpOpen(true);
        setErrorDniText(t('provinceError'));
      }
      if (getGenderCode(values).toLowerCase() !== gender) {
        setErrorDniText(t('genderError'));
        setIsPopUpOpen(true);
      }
    }
  }, [dniResult, values.gender]);

  const closePopUp = (): void => {
    _setDniResult({});
    setValues(INIT_VALUES);
    handleValidate('dni', '');
    handleValidate('idMainIssue', '');
    setIsPopUpOpen(false);
    setErrorDniText('');
  };

  return (
    <>
      <Input
        error={errors.dni}
        {...props}
        value={values.dni}
        onChange={handleOnChange}
        onBlur={handleBlur}
        label="dni_input_label"
        icon={'spinner'}
        iconPosition="right"
        isLoading={dniLoading}
        disabled={dniLoading}
      />
      <div className={_styles.dropdown_select__wrapper}>
        <Typography className={_styles.label} variant="body5">
          {t('gender_label')} <span> *</span>
        </Typography>
        <Dropdown
          list={[
            { value: 'male', label: t('male') },
            { value: 'female', label: t('female') },
          ]}
          selected={{ value: values.gender, label: t(values.gender) }}
          className={_styles.dropdown_select}
          listClassName={_styles.dropdown_select__list}
          handleSelect={handleSelect}
          tabIndex={0}
        />
      </div>
      <Input
        name="idMainIssue"
        value={values.idMainIssue}
        error={errors.idMainIssue}
        required
        type="number"
        onChange={handleOnChange}
        onBlur={handleBlur}
        label="id_main_issue_input_label"
        disabled={dniLoading}
      />
      {isPopUpOpen && (
        <PopUpContainer
          isOpen={isPopUpOpen}
          hasOverlay
          handleClose={closePopUp}
          icon={<SvgIcon src={icons.errorIcon} />}
          handleOK={closePopUp}
          type="small"
          description={errorDniText}
        />
      )}
    </>
  );
};
export default DNI_Input;
