import { FC, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setDeepLink } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { RootState } from 'src/types/store-types';
import { Button } from '../../common/Button';
import './styles.scss';

type Props = {
  placement: string;
  settings: {
    icon: string;
    needLogin: false;
    priority: number;
    styles: Styles;
  };
};
const ReferAFriendWidget: FC<Props> = ({ settings, placement }) => {
  const { styles } = settings;
  const sessionRecorder = useSessionRecorder();
  const { t }: Translation = useTranslation();

  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setDeepLink = useConnectedAction(setDeepLink);

  const { user } = useSelector((state: RootState) => state.user);
  const { deepLink } = useSelector((state: RootState) => state.app);

  const openAccountModal = (): void => {
    sessionRecorder?.trackEvent('"Invite a friend" button click', {
      placement,
    });

    if (user) {
      _openAccountModal({ section: 'referAFriend', subSection: 'referAFriend' });
    } else {
      setBodyOverflow('unset');
      _openAuthModal('login');
    }
  };

  useEffect(() => {
    if (deepLink === 'raf' && user) {
      setTimeout(() => {
        _openAccountModal({ section: 'referAFriend', subSection: 'referAFriend' });
        _setDeepLink('');
      }, 0);
    }
  }, [deepLink, user]);

  return (
    <div className="refer_a_friend_wrapper">
      <Button
        className="refer_a_friend_wrapper_btn"
        fontWeight="bold"
        variant="contained"
        color="transparent"
        onClick={openAccountModal}
        style={styles}
      >
        {isDesktop && t('referAFriendInvite')}
      </Button>
    </div>
  );
};
export default ReferAFriendWidget;
