import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys, EnumStatusesOfKyc } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';
import '../styles.scss';

const CyberSportFrame: FC<FrameTypes> = ({ iframeSrc, iframeId, iframeClasses }) => {
  const { kycStatus, user } = useSelector((state: RootState) => state.user);
  const { section } = useSelector((state: RootState) => state.accountModal);

  const _openDialog = useConnectedAction(openDialog);

  useEffect(() => {
    let interval: any;

    if (
      !section &&
      user &&
      process.env.REACT_APP_KYC_ENABLE === 'true' &&
      (kycStatus === EnumStatusesOfKyc.PENDING ||
        kycStatus === EnumStatusesOfKyc.EXPIRED ||
        kycStatus === EnumStatusesOfKyc.REJECTED ||
        kycStatus === undefined)
    ) {
      interval = setInterval(() => {
        _openDialog({
          dialogType: EnumDialogsKeys.KYC_POPUP,
          dialogProps: { data: {} },
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [kycStatus, user, section]);

  useEffect(() => {
    if (
      user &&
      process.env.REACT_APP_KYC_ENABLE === 'true' &&
      (kycStatus === EnumStatusesOfKyc.PENDING ||
        kycStatus === EnumStatusesOfKyc.EXPIRED ||
        kycStatus === EnumStatusesOfKyc.REJECTED ||
        kycStatus === undefined)
    ) {
      _openDialog({
        dialogType: EnumDialogsKeys.KYC_POPUP,
        dialogProps: { data: {} },
      });
    }
  }, [kycStatus, user]);
  return <iframe className={iframeClasses} id={iframeId} src={iframeSrc.loggedIn} allowFullScreen></iframe>;
};

export default CyberSportFrame;
