import React, { FC, useEffect, useState } from 'react';

const OnlineTimer: FC = () => {
  const [sessionTime, setSessionTime] = useState<number>(() => {
    const storedTime = localStorage.getItem('sessionTime');
    return storedTime ? parseInt(storedTime, 10) : 0; // Initialize from localStorage or start at 0
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setSessionTime((prevTime) => {
        const newTime = prevTime + 1;
        localStorage.setItem('sessionTime', newTime.toString()); // Update localStorage
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return <span>{formatTime(sessionTime)}</span>;
};

export default React.memo(OnlineTimer);
