import { AutoSignInPayload, SignInPayload } from '../../types/req-dto/authorization';
import { ISagaReqStructure } from '../../types/store-types';

export type userState = {
  user: any;
  additionalInfo: any;
  errors: {
    [key: string]: string;
  };
  dniResult: {
    [key: string]: string;
  };
  userUpdateLoading: boolean;
  loading: boolean;
  balanceIsLoading: boolean;
  activateButton: boolean;
  activateNextStep: boolean;
  wallets: any;
  gamePreviewProducts: string[];
  authLoading: boolean;
  password: string;
  repeatPassword: string;
  phoneCode: string;
  kycStatus: string;
  forceLogout: null | { message: string };
  passwordError: string;
  referralLinks: ReferralLinks | null;
  cpfValue: any;
  signinVerificationData: {
    username: string;
    isOpen: boolean;
  };
};

export interface ISignInReq extends ISagaReqStructure {
  data: SignInPayload;
  dispatch: any;
}
export interface IAutoSignInReq extends ISagaReqStructure {
  data: AutoSignInPayload;
  dispatch: any;
}
export interface ISignUpReq extends ISagaReqStructure {
  data: any;
}

export interface IFirstDepositToWallet extends ISagaReqStructure {
  data: {
    bonusConfigId: string;
    isParticipating: boolean;
  };
}

export const APP_BALANCE_REQ = 'app/APP_BALANCE_REQ';
export const APP_BALANCE_SUCCESS = 'app/APP_BALANCE_SUCCESS';
export const APP_BALANCE_FAIL = 'app/APP_BALANCE_FAIL';

export const USER_SIGN_IN_REQ = 'user/USER_SIGN_IN_REQ';
export const USER_AUTO_SIGN_IN_REQ = 'user/USER_AUTO_SIGN_IN_REQ';
export const USER_SIGN_IN_SUCCESS = 'user/USER_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_FAIL = 'user/USER_SIGN_IN_FAIL';
export const USER_SIGN_IN_LOAD = 'user/USER_SIGN_IN_LOAD';

export const USER_SET_ERROR = 'user/USER_SET_ERROR';

export const USER_SET_DNI_RESULT = 'user/USER_SET_DNI_RESULT';

export const USER_SET_LOADING = 'user/USER_SET_LOADING';
export const RESET_USER_STORE = 'user/RESET_STORE';

export const SET_CPF_USER = 'user/SET_CPF_USER';

export const RESET_STORE = 'user/RESET_STORE';

export const LOGOUT = 'user/LOGOUT';

export const TOGGLE_ACTIVATE_BUTTON = 'user/TOGGLE_ACTIVATE_BUTTON';

export const TOGGLE_ACTIVATE_NEXT_BUTTON = 'user/TOGGLE_ACTIVATE_NEXT_BUTTON';

export const APP_SET_PASSWORD = 'user/APP_SET_PASSWORD';
export const APP_SET_PHONE_CODE = 'user/APP_SET_PHONE_CODE';
export const APP_SET_KYC_STATUS = 'user/APP_SET_KYC_STATUS';

export const FORCE_LOGOUT = 'user/FORCE_LOGOUT';
export const RESET_FORCE_LOGOUT = 'user/RESET_FORCE_LOGOUT';

export const APP_SET_PASSWORD_ERROR = 'user/APP_SET_PASSWORD_ERROR';

export const FIRST_DEPOSIT_TO_BONUS_REQUEST = 'user/FIRST_DEPOSIT_TO_BONUS_REQUEST';
export const FIRST_DEPOSIT_TO_BONUS_SUCCESS = 'user/FIRST_DEPOSIT_TO_BONUS_SUCCESS';
export const FIRST_DEPOSIT_TO_BONUS_FAILURE = 'user/FIRST_DEPOSIT_TO_BONUS_FAILURE';

export const SET_REFERRAL_LINKS = 'account/SET_REFERRAL_LINKS';

export const USER_SET_UPDATE_USER = 'user/USER_SET_UPDATE_USER';
export const USER_SET_UPDATE_USER_LOADING = 'user/USER_SET_UPDATE_USER_LOADING';

export const SIGN_IN_VERIFICATION_DATA = 'user/SIGN_IN_VERIFICATION_DATA';

export const APP_SET_CPF_VALUE = 'user/APP_SET_CPF_VALUE';
