import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { Input } from 'src/components/common/Input';
import RadioInput from 'src/components/common/RadioInput';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { getPlayerLimits, setPlayerLimits } from 'src/services/limitsApi';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import './styles.scss';

const ActivityLimitations: FC = () => {
  const { t } = useTranslation();

  const [totalActivityLimits, setTotalActivityLimits] = useState<any>({
    day: '',
    week: '',
    month: '',
  });
  const [limitActions, setLimitActions] = useState<any>({
    day: t('alert'),
    week: t('alert'),
    month: t('alert'),
  });

  const [currentTotalActivityLimits, setCurrentTotalActivityLimits] = useState<any>({
    day: '',
    week: '',
    month: '',
  });
  const [currentLimitActions, setCurrentLimitActions] = useState<any>({
    day: t('alert'),
    week: t('alert'),
    month: t('alert'),
  });

  const _openDialog = useConnectedAction(openDialog);

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>, key: any): void => {
    const { value } = e.target;

    setLimitActions((prevActions: any) => ({
      ...prevActions,
      [key]: value,
    }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    // Allow only numeric values
    if (/^\d*\.?\d*$/.test(value)) {
      setTotalActivityLimits((prevLimits: any) => ({
        ...prevLimits,
        [name]: value,
      }));
    }
  };

  const handleSuccess = (data: any): void => {
    data.upcoming.forEach((element: any) => {
      switch (element.timePeriod) {
        case 'day':
          setTotalActivityLimits((prev: any) => ({ ...prev, day: element.limitValue }));
          setLimitActions((prev: any) => ({ ...prev, day: element.outOfLimitAction }));
          break;
        case 'week':
          setTotalActivityLimits((prev: any) => ({ ...prev, week: element.limitValue }));
          setLimitActions((prev: any) => ({ ...prev, week: element.outOfLimitAction }));
          break;
        case 'month':
          setTotalActivityLimits((prev: any) => ({ ...prev, month: element.limitValue }));
          setLimitActions((prev: any) => ({ ...prev, month: element.outOfLimitAction }));
          break;
        default:
          break;
      }
    });
    data.active.forEach((element: any) => {
      switch (element.timePeriod) {
        case 'day':
          setCurrentTotalActivityLimits((prev: any) => ({ ...prev, day: element.limitValue }));
          setCurrentLimitActions((prev: any) => ({ ...prev, day: element.outOfLimitAction }));
          break;
        case 'week':
          setCurrentTotalActivityLimits((prev: any) => ({ ...prev, week: element.limitValue }));
          setCurrentLimitActions((prev: any) => ({ ...prev, week: element.outOfLimitAction }));
          break;
        case 'month':
          setCurrentTotalActivityLimits((prev: any) => ({ ...prev, month: element.limitValue }));
          setCurrentLimitActions((prev: any) => ({ ...prev, month: element.outOfLimitAction }));
          break;
        default:
          break;
      }
    });
  };

  const getLimits = async (): Promise<void> => {
    const res = await getPlayerLimits();
    if (res.success) {
      handleSuccess(res.result.totalActivity);
    }
  };
  const handleSave = async (): Promise<void> => {
    const payload: any = {
      timezone: -3,
      limits: [],
    };
    Object.keys(totalActivityLimits).forEach((key) => {
      if (totalActivityLimits[key] && key === 'manual') {
        payload.limits.push({
          limitType: 'totalActivity',
          timePeriodHours: totalActivityLimits[key],
          limitValue: totalActivityLimits[key],
          autoSpawn: true,
          timePeriod: 'manual',
          outOfLimitAction: limitActions[key],
        });
      } else if (totalActivityLimits[key]) {
        payload.limits.push({
          limitType: 'totalActivity',
          limitValue: totalActivityLimits[key],
          autoSpawn: true,
          timePeriod: key,
          outOfLimitAction: limitActions[key],
        });
      } else {
        payload.limits.push({
          limitType: 'totalActivity',
          limitValue: 0,
          autoSpawn: true,
          timePeriod: key,
          outOfLimitAction: limitActions[key],
        });
      }
    });
    const res = await setPlayerLimits(payload);
    if (res.success) {
      _openDialog({
        dialogType: EnumDialogsKeys.LIMITS_POPUP,
        dialogProps: { data: t('activityLimits') },
      });
    }
  };
  const handleReset = (period: any): void => {
    setTotalActivityLimits((prev: any) => ({ ...prev, [period]: '' }));
    setLimitActions((prev: any) => ({ ...prev, [period]: t('alert') }));
  };

  useEffect(() => {
    getLimits();
  }, []);

  return (
    <div>
      <p className={'limit_title'}>{t('activityLimits')}</p>

      <div className="limitRow">
        <p className="limitRow_tilte">{t('daily')}</p>
        <div className="limitRow_body">
          <Input
            className="limitRow_input"
            value={totalActivityLimits.day}
            type="text"
            name="day"
            onChange={handleInputChange}
          />
          {t('hour')}
          <div className="limitRow_radio">
            <RadioInput
              data={['alert', 'block']}
              onChange={(e) => handleRadioChange(e, 'day')}
              value={limitActions.day}
              name="day"
            />
          </div>
          <Button className="reset-btn" onClick={() => handleReset('day')}>
            {t('reset')}
          </Button>
        </div>
      </div>
      <div className="limitRow">
        <p className="limitRow_tilte">{t('weekly')}</p>
        <div className="limitRow_body">
          <Input
            className="limitRow_input"
            value={totalActivityLimits.week}
            type="text"
            name="week"
            onChange={handleInputChange}
          />
          {t('hour')}
          <div className="limitRow_radio">
            <RadioInput
              data={['alert', 'block']}
              onChange={(e) => handleRadioChange(e, 'week')}
              value={limitActions.week}
              name="week"
            />
          </div>
          <Button className="reset-btn" onClick={() => handleReset('day')}>
            {t('reset')}
          </Button>
        </div>
      </div>
      <div className="limitRow">
        <p className="limitRow_tilte">{t('monthly')}</p>
        <div className="limitRow_body">
          <Input
            className="limitRow_input"
            value={totalActivityLimits.month}
            type="text"
            name="month"
            onChange={handleInputChange}
          />
          {t('hour')}
          <div className="limitRow_radio">
            <RadioInput
              data={['alert', 'block']}
              onChange={(e) => handleRadioChange(e, 'month')}
              value={limitActions.month}
              name="month"
            />
          </div>
          <Button className="reset-btn" onClick={() => handleReset('day')}>
            {t('reset')}
          </Button>
        </div>
      </div>
      <Button onClick={handleSave} className="limitRow_btn">
        {t('save')}
      </Button>
      <div className="current_limits">
        <p className="current_limits_title">{t('currentLimitsActualAt')}</p>
        <div className="limitRow">
          <p className="limitRow_tilte">{t('daily')}</p>
          <div className="limitRow_body">
            <Input
              className="limitRow_input"
              value={currentTotalActivityLimits.day}
              type="text"
              name="day"
              onChange={handleInputChange}
              disabled
            />
            {t('hour')}
            <div className="limitRow_current_checkbox">
              <div className="limitRow_current_checkbox_circle"></div>
              <span className="limitRow_current_checkbox_text">{currentLimitActions.day}</span>
            </div>
          </div>
        </div>
        <div className="limitRow">
          <p className="limitRow_tilte">{t('weekly')}</p>
          <div className="limitRow_body">
            <Input
              className="limitRow_input"
              value={currentTotalActivityLimits.week}
              type="text"
              name="week"
              onChange={handleInputChange}
              disabled
            />
            {t('hour')}
            <div className="limitRow_current_checkbox">
              <div className="limitRow_current_checkbox_circle"></div>
              <span className="limitRow_current_checkbox_text">{currentLimitActions.week}</span>
            </div>
          </div>
        </div>
        <div className="limitRow">
          <p className="limitRow_tilte">{t('monthly')}</p>
          <div className="limitRow_body">
            <Input
              className="limitRow_input"
              value={currentTotalActivityLimits.month}
              type="text"
              name="month"
              onChange={handleInputChange}
              disabled
            />
            {t('hour')}
            <div className="limitRow_current_checkbox">
              <div className="limitRow_current_checkbox_circle"></div>
              <span className="limitRow_current_checkbox_text">{currentLimitActions.month}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityLimitations;
