import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { formatDate } from 'src/helpers/transformers';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { resetAlertLimit } from 'src/services/limitsApi';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
// import './styles.scss';

interface IProps {
  data?: any;
}
const LimitExceedAlertPopup: FC<IProps> = ({ data }) => {
  const { t }: Translation = useTranslation();

  setBodyOverflow('unset');

  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    setBodyOverflow('set');
    _closeDialog({ dialogType: EnumDialogsKeys.LIMITS_EXCEED_ALERT });
  };

  const onReset = async (): Promise<void> => {
    const res = await resetAlertLimit({ limitType: data.data.limitType, timePeriod: data.data.timePeriod });
    if (res.success) {
      removeDialog();
    }
  };

  return (
    <PopUpContainer
      isOpen
      handleClose={removeDialog}
      handleOK={onReset}
      hasOverlay={false}
      confirmText={t('reset')}
      title={t(data.templateId, { data: formatDate(data.data.endDate) })}
      zIndex={160}
    />
  );
};

export default LimitExceedAlertPopup;
