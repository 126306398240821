import { FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { setSelectedCategory } from 'src/store/app/actions';
import '../styles.scss';

const funMissionModuleName = 'fun-mission';

const FunMissionIframe: FC<FrameTypes> = ({ iframeSrc, iframeId, iframeClasses, user, selectedCategory }) => {
  const { locale } = useI18n();
  const sessionRecorder = useSessionRecorder();

  const moduleLibIntegration = (window as any)?._gamebox_moduleList
    ? (window as any)?._gamebox_moduleList?.includes('fun-mission')
    : null;

  const navigate = useNavigate();
  const [closeGame, setCloseGame] = useState<string>('');
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const iframe = (document?.getElementById(iframeId) as HTMLIFrameElement)?.contentWindow;

  const handleMessages = useCallback(
    (event: { data: { type: SetStateAction<string>; category: string; params: string | number } }): void => {
      setCloseGame('');
      if (event.data.type === 'CHANGE_CATEGORY') {
        _setSelectedCategory(event.data.category);
        iframe?.postMessage({ type: event.data.type, id: event.data.params }, '*');
        navigate(`/category/${event.data.category}`);
      }

      if (event.data.type === 'CLOSE_GAME_BOX' || event.data.type === 'CLOSE_GAME_BOX_GLOBAL_WIDGET') {
        setCloseGame(event.data.type);
      }
    },
    [iframe]
  );

  useEffect(() => {
    if (moduleLibIntegration && selectedCategory === funMissionModuleName) {
      (window as any)?.__gdlib?.startWidget({
        module: funMissionModuleName,
        parentId: 'fun-missionId',
        customClassName: `frame`,
      });
    }
  }, [moduleLibIntegration, selectedCategory]);

  useEffect(() => {
    if (iframe && locale) {
      iframe.postMessage({ type: 'CHANGE_LANGUAGE', language: locale }, '*');
    }
  }, [locale, iframe]);

  useEffect(() => {
    window.addEventListener('message', handleMessages);
    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, [handleMessages]);

  useEffect(() => {
    return () => {
      if (moduleLibIntegration && selectedCategory === funMissionModuleName) {
        (window as any)?.__gdlib?.closeWidget(funMissionModuleName);
      }
    };
  }, []);

  useEffect(() => {
    if (iframe && closeGame) {
      iframe.postMessage({ type: 'CLOSE_GAME_BOX' }, '*');
    }
  }, [iframe, closeGame]);

  useEffect(() => {
    sessionRecorder?.trackEvent('Fun mission page view');
  }, []);

  if (moduleLibIntegration && selectedCategory === funMissionModuleName) {
    return <></>;
  }

  return (
    <iframe
      className={iframeClasses}
      id={iframeId}
      src={user ? iframeSrc?.loggedIn : iframeSrc?.loggedOut}
      allow="autoplay"
    />
  );
};

export default FunMissionIframe;
