import Countdown from 'antd/es/statistic/Countdown';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { Input } from 'src/components/common/Input';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { getSkinId, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import {
  resendEmailOtpRequest,
  validateEmailByOtpRequest,
  verifyEmailByOtpRequest,
} from 'src/services/authorizationApi';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { toggleActivateNextStep } from '../../../../../store/user/actions';
import './styles.scss';

interface IProps {
  email: string;
}

interface IUserRegOtpData {
  endTime: number;
  resendOtpAfter: number;
  requestIdentifier: string;
}

const OtpPopup: FC<IProps> = ({ email }) => {
  const { t } = useTranslation();
  const _toggleActivateNextStep = useConnectedAction(toggleActivateNextStep);
  const skinId = getSkinId();

  const [otpValue, setOtpValue] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState<boolean>(false);
  const [userRegOtpData, setUserRegOtpData] = useState<IUserRegOtpData>({
    endTime: 0,
    resendOtpAfter: 0,
    requestIdentifier: '',
  });

  const _closeDialog = useConnectedAction(closeDialog);

  const closeDialogAndRestore = (): void => {
    _closeDialog({ dialogType: EnumDialogsKeys.OTP_REQUEST_POPUP });
    setBodyOverflow('unset');
  };

  useEffect(() => {
    const verifyOtp = async (): Promise<void> => {
      try {
        const res = await verifyEmailByOtpRequest({ skinId, email });
        if (res?.success && res?.pendingOtpVerification) {
          setIsResendButtonDisabled(true);
          _toggleActivateNextStep(false);
          setUserRegOtpData({
            endTime: Date.now() + res.otpExpiresIn,
            resendOtpAfter: res.resendOtpAfter,
            requestIdentifier: res.requestIdentifier,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    verifyOtp().catch((e) => console.error(e));
  }, [email, skinId]);

  const validateOtpCode = async (): Promise<void> => {
    try {
      const { requestIdentifier } = userRegOtpData;
      const payload = { otp: otpValue, email, requestIdentifier };
      const res = await validateEmailByOtpRequest(payload);

      if (res.success) {
        closeDialogAndRestore();
        _toggleActivateNextStep(true);
        setUserRegOtpData({ endTime: 0, resendOtpAfter: 0, requestIdentifier: '' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOtpInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (value.length <= 6) {
      setOtpValue(value);
      setOtpError(value.length === 6 ? '' : t('otp_validation'));
    }
  };

  useEffect(() => {
    if (isResendButtonDisabled) {
      const timer = setTimeout(() => {
        setIsResendButtonDisabled(false);
      }, userRegOtpData.resendOtpAfter);

      return () => clearTimeout(timer);
    }

    return undefined;
  }, [isResendButtonDisabled, userRegOtpData.resendOtpAfter]);

  const resendOtpCode = async (): Promise<void> => {
    try {
      const { requestIdentifier } = userRegOtpData;
      const payload = { requestIdentifier, skinId, email };
      const res = await resendEmailOtpRequest(payload);

      if (res?.success && res?.pendingOtpVerification) {
        _toggleActivateNextStep(false);
        setIsResendButtonDisabled(true);
        setUserRegOtpData({
          endTime: Date.now() + res.otpExpiresIn,
          resendOtpAfter: res.resendOtpAfter,
          requestIdentifier: res.requestIdentifier,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PopUpContainer
      isOpen
      handleOK={validateOtpCode}
      hasOverlay
      handleClose={closeDialogAndRestore}
      title={t('validate_account_otp', { userEmail: email })}
      confirmText={t('validate')}
      disabled={!otpValue || !!otpError}
      className="otp_popup"
    >
      <Input error={otpError} type="number" name="otpCode" onChange={handleOtpInputChange} value={otpValue} />
      <div className="otp_popup_countdown">
        <Countdown value={userRegOtpData.endTime} format="mm:ss" onFinish={() => closeDialogAndRestore()} />
        <Button size="sm" onClick={resendOtpCode} className="success-filled-btn" disabled={isResendButtonDisabled}>
          {t('resend')}
        </Button>
      </div>
    </PopUpContainer>
  );
};

export default OtpPopup;
