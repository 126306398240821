import { Spin } from 'antd';
import Countdown, { CountdownProps } from 'antd/es/statistic/Countdown';
import cn from 'classnames';
import codes from 'country-calling-code';
import moment from 'moment';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ThemeSwitcher from 'src/components/base-components/ThemeSwitcher';
import { Button } from 'src/components/common/Button';
import { Input } from 'src/components/common/Input';
import PopUpContainer from 'src/components/common/modal/PopUp';
import TimerContext from 'src/components/common/OnlineTimer/OnlineTimer';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { sendEmailOTPRequestAgain, validateEmailOTPRequest } from 'src/services/authorizationApi';
import HttpClient from 'src/services/http';
import { setUpdateUser, signIn } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import { APP_API_VERSION, PERSONAL_INFO_FIELD_DESKTOP, PERSONAL_INFO_FIELD_MOBILE } from 'src/utils/constants';
import AccountDetail from './AccountDetail';
import _styles from './styles.module.scss';

const AccountDetails: FC = () => {
  const { t }: Translation = useTranslation();

  const [openOtpPopUp, setOpenOtpPopUp] = useState<boolean>(false);
  const [otpValue, setOtpValue] = useState<any>('');
  const [otpError, setOtpError] = useState<string>('');
  const [isBtnDisable, setIsBtnDisable] = useState<boolean>(true);
  const [requestIdentifier, setRequestIdentifier] = useState<string>('');
  const [playerRegOtpData, setPlayerRegOtpData] = useState<Record<string, string>>({});
  const [validateRequestLoading, setValidateRequestLoading] = useState<boolean>(false);
  const startTime = Date.now();

  const { user, userUpdateLoading } = useSelector((state: RootState) => state.user);
  const updatePersonalInfo = useConnectedAction(setUpdateUser);
  const setUserData = useConnectedAction(signIn.success);

  const classes = `${cn('primaryButton', 'success-filled-btn', { ['disabled']: validateRequestLoading })}`;

  const updateField = (key: string, fieldValue: string): void => {
    updatePersonalInfo({ [key]: fieldValue });
  };

  const handleVerify = async (): Promise<void> => {
    setValidateRequestLoading(true);
    const res = await HttpClient.post({
      path: `${APP_API_VERSION}/verifyEmail`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    });
    if (res.success) {
      setRequestIdentifier(res.requestIdentifier);
      const endTime = Date.now() + res?.otpExpiresIn;
      setPlayerRegOtpData({
        endTime,
        resendOtpAfter: res?.resendOtpAfter,
        requestIdentifier: res?.requestIdentifier,
      });
      setOpenOtpPopUp(true);
    }
    setValidateRequestLoading(false);
  };

  const validateOtpCode = (): void => {
    const payload = {
      otp: otpValue,
      requestIdentifier,
    };
    validateEmailOTPRequest(payload).then((res) => {
      if (res?.success) {
        const newUser: any = {
          ...user,
          personalInfo: {
            ...user.personalInfo,
            emailVerified: true,
          },
        };
        localStorage.setItem('user', JSON.stringify(newUser));
        setUserData(newUser);
        setOpenOtpPopUp(false);
        setPlayerRegOtpData({});
        setOtpError('');
        setOtpValue('');
      }
    });
  };
  const changeOtpCode = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (value.length <= 6) {
      setOtpValue(value);
      setOtpError(value.length === 6 ? '' : t('otp_validation'));
    }
  };

  const closePopUp = (): void => {
    setPlayerRegOtpData({});
    setOtpError('');
    setOtpValue('');
    setOpenOtpPopUp(false);
  };

  const sendAgainCode = (): void => {
    const { requestIdentifier } = playerRegOtpData;
    const payload = {
      requestIdentifier,
    };
    sendEmailOTPRequestAgain(payload).then((res: any) => {
      if (res?.message === 'otp_updated') {
        const endTime = Date.now() + res?.otpExpiresIn;
        setRequestIdentifier(res.requestIdentifier);
        setPlayerRegOtpData({
          endTime,
          resendOtpAfter: res?.resendOtpAfter,
          requestIdentifier: res?.requestIdentifier,
        });
        setIsBtnDisable(true);
      }
    });
  };

  const onChange: CountdownProps['onChange'] = () => {
    const { resendOtpAfter } = playerRegOtpData;
    const currentTime = Date.now();
    const elapsedTime = currentTime - startTime;
    const elapsedMinutes = elapsedTime / +resendOtpAfter;
    if (elapsedMinutes >= 1) {
      setIsBtnDisable(false);
    }
  };

  const userCountry = useMemo(() => {
    const result = codes.find((c) => c.isoCode3 === user?.countryCode);

    return result ? result.country : null;
  }, [user]);

  const birthDateArr: any = useCallback((el: string) => {
    if (el) {
      return {
        day: moment(el).format('DD'),
        month: moment(el).format('MM'),
        year: moment(el).format('YYYY'),
      };
    }

    return { day: null, month: null, year: null };
  }, []);

  const content = useCallback(
    (el: string) => {
      const EDITABLE_FIELDS = ['email', 'phoneNumber', 'address', 'city'];
      if (el === 'birthDate') {
        return (
          <>
            {Object.keys(birthDateArr(user?.personalInfo?.[el])).map((info: string) => {
              return (
                <div key={info} className={_styles.container__birthDate_item}>
                  <AccountDetail
                    key={el}
                    value={birthDateArr(user?.personalInfo?.[el])?.[info]}
                    title={t(info)}
                    fieldName={el}
                  />
                </div>
              );
            })}
          </>
        );
      } else if (el === 'countryCode') {
        return <AccountDetail value={userCountry} title={t(`personalInfo_${el}`)} fieldName={el} />;
      } else if (el === 'smartico') {
        // work only in dev
        return (
          <Button className="secondary-btn" size="lg" onClick={() => (window as any)?._smartico.dp('dp:gf')}>
            My Space
          </Button>
        );
      } else {
        return (
          <AccountDetail
            value={user?.personalInfo?.[el]}
            title={`personalInfo_${el}`}
            emailVerifed={el === 'email' && user?.personalInfo?.emailVerified}
            isEditable={EDITABLE_FIELDS.includes(el)}
            updateField={updateField}
            fieldName={el}
          />
        );
      }
    },
    [user]
  );

  return (
    <div className={_styles.container}>
      {userUpdateLoading ? (
        <Spin />
      ) : (
        <>
          <div>
            <p className={_styles.container__support}>{t('customer_support')}</p>
            <div>
              <p className={_styles.container__support}>
                {t('session_time')}: <TimerContext />
              </p>
            </div>
            {process.env.REACT_APP_EMAIL_VERIFICATION === 'true' && !user?.personalInfo?.emailVerified && (
              <p className={cn(_styles.container__support, _styles.noMargin)}>
                <Button className={_styles.verifyBtn} onClick={handleVerify} disabled={!user?.personalInfo?.email}>
                  {t('verifyEmailBtnText')}
                </Button>
              </p>
            )}
          </div>
          {(isMobile ? PERSONAL_INFO_FIELD_MOBILE : PERSONAL_INFO_FIELD_DESKTOP).map((el: string) => {
            if ((el === 'theme' || el === 'smartico') && process.env.REACT_APP_MODE !== 'DEV') {
              return null;
            }
            return (
              <div key={el} className={_styles.container__item}>
                {content(el)}
              </div>
            );
          })}
          {process.env.REACT_APP_THEME_MODE && (
            <div className={_styles.container__item}>
              <ThemeSwitcher />
            </div>
          )}
        </>
      )}
      {openOtpPopUp && (
        <PopUpContainer
          hasOverlay
          title={t('validate_account_otp', { userEmail: user.personalInfo.email })}
          confirmText={t('validate')}
          isOpen={openOtpPopUp}
          handleOK={validateOtpCode}
          handleClose={closePopUp}
          disabled={!otpValue || !!otpError}
          className="otp_popup"
          zIndex={160}
        >
          <Input error={t(otpError)} type="number" name="otpCode" onChange={changeOtpCode} value={otpValue} />
          <div className="otp_popup_countDown" style={{ justifyContent: 'space-between' }}>
            <Countdown value={playerRegOtpData.endTime} format="mm:ss" onChange={onChange} />
            <Button className={classes} size="sm" onClick={sendAgainCode} disabled={isBtnDisable}>
              {t('resend')}
            </Button>
          </div>
        </PopUpContainer>
      )}
    </div>
  );
};
export default AccountDetails;
