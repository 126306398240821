import cn from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { checkStatusOfKyc } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { CMSManager } from 'src/manager/CMSManager';
import { getStaticPagesFromCms } from 'src/services/dataApi';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setBottomNavigation } from 'src/store/configs/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

type Props = {
  selectedSetting: string;
  setSelectedSetting: (setting: string) => void;
  sections: any[];
};
const MobileSections: FC<Props> = ({ selectedSetting, setSelectedSetting, sections }) => {
  const { t }: Translation = useTranslation();
  const { locale } = useI18n();
  const _setBottomNavigation = useConnectedAction(setBottomNavigation);
  const { bottomNavigation } = useSelector((state: RootState) => state.configs);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const { kycStatus } = useSelector((state: RootState) => state.user);
  const sessionRecorder = useSessionRecorder();

  const [selectedSubsection, setSelectedSubsection] = useState<string>('');

  const changeSelectedSubsection = (subSection: string, section: string, subTitle?: string): void => {
    setSelectedSubsection(subSection);
    _openAccountModal({ section: section, subSection: subSection, subTitle: subTitle });
  };

  const getStaticPageData = (): void => {
    if (bottomNavigation.length === 0) {
      getStaticPagesFromCms(locale).then((res) => {
        if (res?.data?.[0]) {
          const _ = CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string].getInfoAndRulesData(res.data);
          _setBottomNavigation(_);
        } else {
          console.error('bottom navigation from csm is not found');
        }
      });
    }
  };

  const toggleSetting = (section: Section) => () => {
    if (section.children.length === 0 && section.link.section) {
      _openAccountModal({ section: section.link.section, subSection: section.link.subSection });
    }

    setSelectedSetting(selectedSetting === section.name ? '' : section.name);

    sessionRecorder?.trackEvent('Header user setting button click', {
      section,
    });
  };

  useEffect(() => {
    getStaticPageData();
  }, [locale]);

  const setSubSectionList = useCallback(
    (subSections: any[], sectionName: string) => {
      return (
        <ul className="panel">
          {subSections.map((subSection, j: number) => (
            <li
              className="panel-list-item"
              key={subSection?.title ? j + subSection?.title : j + subSection}
              style={{
                color:
                  selectedSubsection === (subSection?.title ? subSection?.title : subSection)
                    ? '#FFBF17'
                    : 'var(--primary-0)',
              }}
              onClick={() =>
                changeSelectedSubsection(
                  subSection?.name ? subSection?.name : subSection,
                  sectionName,
                  subSection?.title
                )
              }
            >
              {t(subSection?.title ? subSection?.title : subSection)}
            </li>
          ))}
        </ul>
      );
    },
    [sections]
  );

  return (
    <>
      {sections?.map((section: Section) => {
        return (
          <li key={section.name}>
            <Button className="accordion" onClick={toggleSetting(section)} justifyContentValue="space-between">
              <div className="category">
                <SvgIcon src={`${section.icon}`} />
                {t(section.name)}
              </div>
              {section.children.length > 0 && (
                <SvgIcon
                  src={icons.arrowDown}
                  className={cn('arrow', { ['rotateArrow']: selectedSetting === section.name })}
                />
              )}
              {section?.notPassedIcon &&
                section?.passedIcon &&
                (checkStatusOfKyc(kycStatus) ? (
                  <img src={section?.passedIcon} alt="passedIcon" width="24" />
                ) : (
                  <img src={section?.notPassedIcon} alt="notPassedIcon" width="24" />
                ))}
            </Button>
            {!!section.children.length &&
              selectedSetting === section.name &&
              setSubSectionList(section.children, section.name)}
          </li>
        );
      })}
    </>
  );
};
export default MobileSections;
