/* eslint-disable sonarjs/cognitive-complexity */
import cn from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { replaceImgPath } from 'src/helpers/transformers';
import { checkDeviceCompatibility, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys, EnumStatusesOfKyc } from 'src/store/dialog/types';
import { setSelectedGame } from 'src/store/games/actions';
import { RootState } from 'src/types/store-types';
import { GAME_CARD_SIZES, TEMPLATE_RADIUS } from 'src/utils/constants';
import GameCardSceleton from '../GameCardSceleton/GameCardSceleton';
import PopUpContainer from '../modal/PopUp';
import { SvgIcon } from '../SvgIcon';
import { Typography } from '../Typography';
import './styles.scss';

type GameCard = {
  data: {
    thumbnail: string;
    name: string;
    providerName: string;
    id: number;
    cols?: number;
    rows?: number;
    productId?: any;
    gameKey?: any;
  };
  showDetails?: boolean;
  loader?: string;
  useRatio?: boolean;
  effects: {
    hoverType?: string;
    src?: string;
    text?: string;
  };
  template?: string;
  size: 'lg' | 'md_l' | 'md_m' | 'md_s' | 'md_xs' | 'sm_l' | 'sm';
  useGif?: boolean;
};
const GameCard: FC<GameCard> = ({
  data,
  showDetails,
  effects,
  size,
  useRatio = false,
  template = 'template_noFullScreen',
  useGif = false,
}) => {
  const { isPortrait } = useMobileOrientation();
  const { t }: Translation = useTranslation();
  const { thumbnail, name, providerName } = data;
  const { hoverType, src, text } = effects;
  const deviceCompatibilityStatus = checkDeviceCompatibility().compatible;

  const _openAuthModal = useConnectedAction(openAuthModal);
  const _selectedGame = useConnectedAction(setSelectedGame);
  const _openDialog = useConnectedAction(openDialog);

  const { user, gamePreviewProducts, kycStatus } = useSelector((state: RootState) => state.user);
  const { egtGamesStats } = useSelector((state: RootState) => state.games);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);
  const { cookiesAccepted } = useSelector((state: RootState) => state.app);

  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);

  const [hovered, setHovered] = useState<boolean>(false);

  const cardSizes = useMemo(() => {
    if (isMobileOnly) {
      if (showDetails) {
        return {
          height: 'auto',
          width: '29vw',
        };
      }

      return {
        height: `${GAME_CARD_SIZES[size]}px`,
        width: template === 'template_fullScreen' ? '29vw' : `${GAME_CARD_SIZES[size]}px`,
      };
    }

    return {
      height: showDetails ? '240px' : `${GAME_CARD_SIZES[size] - (template === 'template_fullScreen' ? 20 : 0)}px`,
      width: useRatio ? 'max-content' : `${GAME_CARD_SIZES[size] + (showDetails ? 20 : 0)}px`,
    };
  }, [size, window.innerWidth, showDetails, useRatio, template, isPortrait]);

  const imgSizes = useMemo(() => {
    if (isMobileOnly) {
      if (showDetails) {
        return {
          height: '105px',
          width: '105px',
        };
      }

      return {
        height: `${(window.innerWidth * 28) / 100}px`,
        width: `${(window.innerWidth * 28) / 100}px`,
      };
    }

    return {
      height: showDetails ? '190px' : `${GAME_CARD_SIZES[size] - (template === 'template_fullScreen' ? 20 : 0)}px`,
      width: useRatio ? 'max-content' : `${GAME_CARD_SIZES[size]}px`,
    };
  }, [size, window.innerWidth, showDetails, isPortrait]);

  const hoverGameitem = (): void => {
    setHovered((prevState) => !prevState);
  };

  const onUnhoveredGameClick = (): void => {
    !hoverType && onGameClick();
  };

  const onGameClick = (): void => {
    if (
      process.env.REACT_APP_USE_COOKIES_GAMECARD === 'true' &&
      process.env.REACT_APP_USE_COOKIES === 'true' &&
      !cookiesAccepted
    ) {
      _openDialog({
        dialogType: EnumDialogsKeys.COOKIES_POPUP,
        dialogProps: { data: {} },
      });
    } else if (process.env.REACT_APP_DEVICE_COMPATIBILITY_ENABLE === 'true' && !deviceCompatibilityStatus) {
      _openDialog({
        dialogType: EnumDialogsKeys.DEVICE_COMPATIBILITY_POPUP,
        dialogProps: { data: {} },
      });
    } else if (
      user &&
      process.env.REACT_APP_KYC_ENABLE === 'true' &&
      (kycStatus === EnumStatusesOfKyc.PENDING ||
        kycStatus === EnumStatusesOfKyc.EXPIRED ||
        kycStatus === EnumStatusesOfKyc.REJECTED ||
        kycStatus === undefined)
    ) {
      _openDialog({
        dialogType: EnumDialogsKeys.KYC_POPUP,
        dialogProps: { data: {} },
      });
    } else {
      if (user) {
        setBodyOverflow('unset');
        if (generalConfigs?.gameLaunchPreview && gamePreviewProducts.includes(data.productId)) {
          _openDialog({
            dialogType: EnumDialogsKeys.GAME_LAUNCH_PREVIEW,
            dialogProps: { data: data },
          });
        } else {
          if ((window as any)?._gameBoxReady) {
            console.log('Success! The game has been opened.');
          } else {
            setIsPopUpOpen(true);
            return;
          }
          _selectedGame(data);
        }
      } else {
        setBodyOverflow('unset');
        _openAuthModal('login');
      }
    }
  };

  const convertIamgeUrl = useCallback(
    (thumbnail: string, width: string, height: string): string => {
      if (process.env.REACT_APP_CONVERT_IMAGE_PATH_WITH_IMIGIX) {
        const convertConfigs = JSON.parse(process.env.REACT_APP_CONVERT_IMAGE_PATH_WITH_IMIGIX as any);
        if (convertConfigs?.gameThumbnails) {
          return replaceImgPath(thumbnail, width, height);
        }
      }
      return thumbnail;
    },
    [data, imgSizes]
  );

  const jackpotStat = useMemo(() => {
    const obj = egtGamesStats?.find((stats: any) => {
      return stats.key.includes(data.gameKey);
    });
    return obj?.value;
  }, [data, egtGamesStats]);

  const closePopUp = (): void => {
    setIsPopUpOpen(false);
  };

  return (
    <>
      {isPopUpOpen && (
        <PopUpContainer
          isOpen={isPopUpOpen}
          hasOverlay
          handleClose={closePopUp}
          handleOK={closePopUp}
          description={t('gameSlowText')}
        />
      )}
      <div className={cn('game', { ['showDetails']: showDetails })} style={cardSizes} onClick={onUnhoveredGameClick}>
        <div
          className="image_wrapper"
          style={template === 'template_2' ? cardSizes : {}}
          onMouseEnter={hoverGameitem}
          onMouseLeave={hoverGameitem}
        >
          {thumbnail && (
            <img
              src={convertIamgeUrl(thumbnail, imgSizes.width, imgSizes.height)}
              loading="lazy"
              style={{
                height: imgSizes.height,
                width: imgSizes.width,
                minWidth: imgSizes.width,
                borderRadius: TEMPLATE_RADIUS[generalConfigs?.template.split(' ')[0] || 'template_noFullScreen'],
              }}
              className="game__image"
              alt={name}
            />
          )}
          {process.env.REACT_APP_ENABLE_EGT_WIDGETS === 'true' && jackpotStat && (
            <span className="jackpot_value">{jackpotStat}</span>
          )}
          <GameCardSceleton
            useGif={useGif}
            height={imgSizes.height}
            width={imgSizes.width}
            borderRadius={
              TEMPLATE_RADIUS[
                generalConfigs?.template?.split(' ')[0] || generalConfigs?.template || 'template_noFullScreen'
              ]
            }
          />
          {hovered && hoverType && (
            <div
              className="game_overlay"
              style={{
                borderRadius: TEMPLATE_RADIUS[generalConfigs?.template.split(' ')[0] || 'template_noFullScreen'],
              }}
            >
              {hoverType === 'fullWidthImage' ? (
                <div className="img_play" onClick={onGameClick}></div>
              ) : (
                <div className="btn_play">
                  {src && <SvgIcon src={src} className="btn_play_icon" />}
                  {text}
                </div>
              )}
            </div>
          )}
        </div>
        {showDetails && (
          <div className="details">
            <Typography variant="h6" className="games__title">
              {name}
            </Typography>
            <Typography variant="h6" className="games__provider_name">
              {providerName}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};
export default GameCard;
