import cn from 'classnames';
import { FC, useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { MediaManager } from 'src/manager/MediaManager';

type Props = {
  groupedPayments?: { [key: string]: any[] } | null;
  group: string;
  selectedGroupOfPayment: string;
  selectPaymentGroup?: (group: string) => void;
};
const GroupedPayment: FC<Props> = ({ group, selectedGroupOfPayment, selectPaymentGroup }) => {
  /*  const duraction = useMemo(() => {
    if (Boolean(groupedPayments?.[group]?.[0]?.duration?.min || groupedPayments?.[group]?.[0]?.duration?.max)) {
      let _dur = `${t('duration')} - `;
      groupedPayments?.[group][0]?.duration?.min && (_dur += groupedPayments?.[group][0]?.duration.min);
      groupedPayments?.[group][0]?.duration?.max && (_dur += '-' + groupedPayments?.[group][0]?.duration.max);
      _dur += t('dur_min');
      return _dur;
    }

    return null;
  }, [groupedPayments, group]);*/

  const icon = useCallback(
    (group: string) => {
      const basePath = '/paymentGroupIcons/';
      const pngPath = `${group}${isMobileOnly ? '_mobile' : ''}.png`;
      const svgPath = `${group}${isMobileOnly ? '_mobile' : ''}.svg`;

      return (
        <img
          className="group_image"
          src={MediaManager.getSrcFromMediaAssets(pngPath, basePath)}
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = MediaManager.getSrcFromMediaAssets(svgPath, basePath);
          }}
          alt={`${group} icon`}
        />
      );
    },
    [isMobileOnly]
  );

  return (
    <div
      className={cn('group_item', { ['active']: selectedGroupOfPayment === group })}
      onClick={() => selectPaymentGroup && selectPaymentGroup(group)}
    >
      {/*    {(duraction || isMobileOnly) && (
        <div className="bottom_container">
          <span className="active_container">{duraction && <span>{duraction}</span>}</span>
        </div>
      )}*/}
      {icon(group)}
    </div>
  );
};
export default GroupedPayment;
