import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { openAuthModal } from '../../../../../store/auth-modal/actions';

interface IProps {
  data?: any;
}
const RegionPopup: FC<IProps> = () => {
  const { t }: Translation = useTranslation();

  setBodyOverflow('unset');

  const _openAuthModal = useConnectedAction(openAuthModal);
  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    setBodyOverflow('set');
    _closeDialog({ dialogType: EnumDialogsKeys.REGION_POPUP });
  };

  const onOk = (): void => {
    _openAuthModal(null);
    removeDialog();
  };

  return <PopUpContainer isOpen handleOK={onOk} hasOverlay title={t('region_status')} zIndex={250} />;
};

export default RegionPopup;
