import { FC, useEffect } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setDeepLink } from 'src/store/app/actions';
import { RootState } from 'src/types/store-types';
import { Button } from '../../common/Button';
import { SvgIcon } from '../../common/SvgIcon';
import './styles.scss';

type Props = {
  settings: {
    icon: string;
    needLogin: false;
    priority: number;
    styles: Styles;
    classes: string;
  };
};
const Deposit: FC<Props> = ({ settings }) => {
  const { styles, icon, classes } = settings;

  const { t }: Translation = useTranslation();
  const sessionRecorder = useSessionRecorder();

  const { deepLink } = useSelector((state: RootState) => state.app);

  const _setDeepLink = useConnectedAction(setDeepLink);
  const _openAccountModal = useConnectedAction(openMyAccountModal);

  const openDepositModal = (): void => {
    sessionRecorder?.trackEvent('Header "deposit" button click');

    _openAccountModal({ section: 'deposit', subSection: 'deposit' });
    isMobileOnly && setBodyOverflow('unset');
  };

  useEffect(() => {
    if (deepLink === 'deposit') {
      setTimeout(() => {
        openDepositModal();
        _setDeepLink('');
      }, 0);
    }
  }, [deepLink]);

  return (
    <div className="deposit_wrapper">
      <Button
        fontWeight="bold"
        variant="contained"
        color="transparent"
        onClick={openDepositModal}
        style={styles}
        className={classes}
      >
        {isMobile ? (
          <>
            <SvgIcon src={`/images/icons/header/plus.svg`} styles={styles} />
            <SvgIcon src={`/images/icons/header/coins.svg`} styles={styles} />
          </>
        ) : (
          <>
            {!!icon?.length && <SvgIcon src={`images/${icon}`} styles={styles} />}
            {t('button_deposit')}
          </>
        )}
      </Button>
    </div>
  );
};
export default Deposit;
