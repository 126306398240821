import { useEffect, useRef } from 'react';
import io from 'socket.io-client';

const useActivitySocket = (user: any): any => {
  const socketRef: any = useRef(null);

  useEffect(() => {
    if (user?.token) {
      // Initialize the socket connection when user is logged in
      socketRef.current = io(`${process.env.REACT_APP_ACTIVITY_SOCKET}?token=${user.token}`, {
        transports: ['websocket'],
      });

      console.log('Socket connected');

      // Optional: Listen for connection events
      socketRef.current.on('connect', () => {
        console.log('Socket successfully connected');
      });

      socketRef.current.on('disconnect', () => {
        console.log('Socket disconnected');
      });
    } else {
      // Disconnect the socket if user logs out or token is null
      if (socketRef.current) {
        socketRef.current.disconnect();
        console.log('Socket disconnected due to logout or missing token');
      }
    }

    // Cleanup on component unmount or user change
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        console.log('Socket disconnected on cleanup');
      }
    };
  }, [user]);

  return socketRef.current;
};

export default useActivitySocket;
