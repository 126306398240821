import React, { useCallback, useMemo } from 'react';
import { isDesktop } from 'react-device-detect';
import TabItemTemplate1 from '../TabItemTemplate1';
import TabItemTemplate2 from '../TabItemTemplate2';

type TabsProps = {
  paymentMethodsData: PaymentMethod[];
  groupedPayments: Record<string, PaymentMethod[]> | null;
  selectedPayment: PaymentMethod;
  selectedPaymentID: number;
  handlePaymentTabClick: (st: number) => void;
};

const PaymentTabs: React.FC<TabsProps> = ({
  paymentMethodsData,
  groupedPayments,
  selectedPayment,
  selectedPaymentID,
  handlePaymentTabClick,
}) => {
  const device = useMemo(() => {
    if (isDesktop) {
      return 'desktop';
    } else {
      return 'mobile';
    }
  }, []);
  const tabs = useCallback(
    (paymentItem: any) => {
      if (groupedPayments) {
        return (
          <TabItemTemplate2
            key={paymentItem.id}
            imgUrl={paymentItem.icons?.[device]?.path}
            name={paymentItem?.name}
            paymentItem={paymentItem}
            selectedPaymentID={selectedPaymentID}
            handlePaymentTabClick={handlePaymentTabClick}
          />
        );
      }

      return (
        <TabItemTemplate1
          key={paymentItem.id}
          imgUrl={paymentItem.icons?.[device]?.path}
          name={paymentItem?.name}
          paymentItem={paymentItem}
          selectedPaymentID={selectedPaymentID}
          handlePaymentTabClick={handlePaymentTabClick}
        />
      );
    },
    [selectedPaymentID, groupedPayments, handlePaymentTabClick, device]
  );

  const paymentItems = groupedPayments?.[selectedPayment?.group || ''] ?? paymentMethodsData;

  return <>{paymentItems?.length > 1 && paymentItems?.map((paymentItem: any) => tabs(paymentItem))}</>;
};

export default PaymentTabs;
