import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { EnumStatusesOfKyc } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';
import './styles.scss';

const Kyc: FC = () => {
  const { t } = useTranslation();
  const [statusText, setStatusText] = useState<string>('');
  const { kycStatus } = useSelector((state: RootState) => state.user);
  const { personalInfo } = useSelector((state: RootState) => state.user?.user || {});

  const _openAccountModal = useConnectedAction(openMyAccountModal);

  const handleUpload = async (): Promise<void> => {
    const sdkInstance = (window as any)?.Legitimuz({
      token: '5cb851a5-fb77-4474-95ca-1780a5fc3bab',
      host: 'https://api.legitimuz.com',
      onSuccess: () => {
        // setTimeout(() => {
        //   if (!isWithdrawelInProcess.current) {
        //     isWithdrawelInProcess.current = true;
        //     _setTransactions({ ...transactionData, ...paymentFields });
        //   }
        // }, 0);
        // setTimeout(() => {
        //   isWithdrawelInProcess.current = false;
        // }, 10000);
      },
    });
    sdkInstance.mount();
    sdkInstance.verifyDocument({ cpf: personalInfo.nationalId });
  };

  const openMyAccount = (): void => {
    _openAccountModal({ section: 'my_account', subSection: 'personal_info' });
  };

  const checkStatusOfKyc = (): void => {
    switch (kycStatus) {
      case EnumStatusesOfKyc.PENDING:
        setStatusText(t('kyc_image_pending'));
        break;
      case EnumStatusesOfKyc.REJECTED:
        setStatusText(t('kyc_image_rejected'));
        break;
      case EnumStatusesOfKyc.VERIFIED:
        setStatusText(t('kyc_image_verified'));
        break;
      case EnumStatusesOfKyc.EXPIRED:
        setStatusText(t('kyc_image_expired'));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (kycStatus) {
      checkStatusOfKyc();
    }
  }, [kycStatus]);

  return (
    <div className="kyc_container">
      <p>{t('kycText')}</p>
      <Button
        disabled={kycStatus === EnumStatusesOfKyc.VERIFIED || kycStatus === EnumStatusesOfKyc.PENDING}
        className="kyc_container_btn"
        type="button"
        onClick={handleUpload}
      >
        {t('kyc')}
      </Button>
      <p className="kyc_info_message">
        {statusText}
        {kycStatus === EnumStatusesOfKyc.EXPIRED && (
          <Button className="kyc_container_btn kyc_info_message_btn" type="button" onClick={openMyAccount}>
            {t('my_account')}
          </Button>
        )}
      </p>
    </div>
  );
};

export default Kyc;
