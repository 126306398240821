import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { Typography } from '../Typography';
import './styles.scss';

type Props = {
  steps: number[] | undefined;
  classes: string;
  title: string;
  content?: string;
  onSubmit: () => void;
};
const SuccessfullyReged: FC<Props> = ({ classes, onSubmit, title, content }) => {
  const { t }: Translation = useTranslation();

  return (
    <div className="successfully_reged_container">
      <div className="tab_container">
        <div className="tab done">
          <span className="step">
            <SvgIcon src={icons.done} />
          </span>
        </div>
      </div>
      <Typography variant="h4" className="success_title">
        {title}
      </Typography>
      {content && (
        <div className="reg_success_container">
          <p>{content}</p>
        </div>
      )}
      <div className="buttons_container">
        <Button className={classes} onClick={onSubmit}>
          {t('okay')}
        </Button>
      </div>
    </div>
  );
};

export default SuccessfullyReged;
