import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { checkStatusOfKyc } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { RootState } from 'src/types/store-types';

type Props = {
  toggleUserSettings: () => void;
  sections: any[];
};
const DesktopSections: FC<Props> = ({ toggleUserSettings, sections }) => {
  const { t }: Translation = useTranslation();
  const { kycStatus } = useSelector((state: RootState) => state.user);
  const sessionRecorder = useSessionRecorder();

  const _openAccountModal = useConnectedAction(openMyAccountModal);

  const { bottomNavigation } = useSelector((state: RootState) => state.configs);

  const openAccountModal = (section: string, subSection: string) => () => {
    let _subSection = '';

    if (section === 'info_and_rules') {
      _subSection = bottomNavigation?.[0]?.name;
    } else {
      _subSection = subSection;
    }

    _openAccountModal({ section: section, subSection: _subSection });
    toggleUserSettings();

    sessionRecorder?.trackEvent('Header user setting button click', {
      section,
    });
  };

  return (
    <>
      {sections?.map((section: Section) => {
        return (
          <li key={section.name}>
            <Button
              onClick={openAccountModal(section.link.section, section.link.subSection)}
              icon={section.icon}
              justifyContentValue="flex-start"
            >
              {t(section.name)}
              {section?.notPassedIcon &&
                section?.passedIcon &&
                (checkStatusOfKyc(kycStatus) ? (
                  <img src={section?.passedIcon} alt="passedIcon" width="24" />
                ) : (
                  <img src={section?.notPassedIcon} alt="notPassedIcon" width="24" />
                ))}
            </Button>
          </li>
        );
      })}
    </>
  );
};
export default DesktopSections;
