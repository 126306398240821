import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { envBoolean } from 'src/helpers/utils';
import { SessionRecorder, SessionRecorderTypes } from 'src/types/services/SessionRecorder';
import { RootState } from 'src/types/store-types';

let isLoaded = false;
const recorders: Record<'clarityRecorder' | 'posthogRecorder', SessionRecorder | null> = {
  clarityRecorder: null,
  posthogRecorder: null,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSessionRecorder = () => {
  const user = useSelector((state: RootState) => state.user.user);

  const identifyUser = useCallback(
    async (id: string, username: string): Promise<void> => {
      if (!isLoaded) {
        await loadRecorders();
      }

      Object.values(recorders).forEach((recorder) => {
        recorder?.identifyUser({ id, username });
      });
    },
    [isLoaded]
  );

  const trackEvent = useCallback(
    async (eventName: SessionRecorderTypes, data?: Record<string, any>): Promise<void> => {
      if (!isLoaded) {
        await loadRecorders();
      }

      Object.values(recorders).forEach((recorder) => {
        recorder?.trackEvent(eventName, data);
      });
    },
    [isLoaded]
  );

  const loadRecorders = async (): Promise<void> => {
    if (isLoaded) return;

    const [clarityModule, posthogModule] = await Promise.all([
      import('../services/clarityRecorder'),
      import('../services/posthogRecorder'),
    ]);

    recorders.clarityRecorder = new clarityModule.ClarityRecorder();
    recorders.posthogRecorder = new posthogModule.PosthogRecorder();
    isLoaded = true;
  };

  if (
    process.env.NODE_ENV === 'development' ||
    !envBoolean(process.env.REACT_APP_SESSION_RECORDING as string) ||
    !user
  ) {
    return null;
  }

  return { identifyUser, trackEvent };
};
