/* eslint-disable sonarjs/cognitive-complexity */
import moment from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Typography } from 'src/components/common/Typography';
import { BASE_URL } from 'src/utils/constants';
import { SvgIcon } from '../components/common/SvgIcon';
import Tooltip from '../components/common/Tooltip/Tooltip';
import { icons } from '../configs/icons';
import { SignInPayload } from '../types/req-dto/authorization';
import { formatCurrency, getSkinId } from './utils';

const makeSignUpPayload = (data: any[], affiliateId: string): Record<string, string> => {
  const skinId = getSkinId();
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return data.reduce((acc: any, cur: any) => {
    Object.keys(cur).forEach((key: string) => {
      if (affiliateId) {
        acc['parentId'] = affiliateId;
      }

      if (key === 'parentId') {
        if (cur['parentId'].value) {
          acc[key] = cur[key].value?.trim();
        }
      } else if (key === 'gender') {
        acc[key] = cur[key].value[0];
      } else if (key === 'dni') {
        acc.dni = cur[key].value?.dni;
        acc.gender = cur[key].value?.gender[0];
        acc.idMainIssue = cur[key].value?.idMainIssue;
      } else {
        acc[key] = typeof cur[key].value === 'string' ? cur[key].value?.trim() : cur[key].value;
      }
    });

    acc['skinId'] = skinId;
    acc['clientBaseUrl'] = BASE_URL;
    if (process.env.REACT_APP_WEBSITENAME === 'PapiGames' && sessionStorage.trackerID) {
      acc['tracker'] = sessionStorage.trackerID;
    }
    if (process.env.REACT_APP_WEBSITENAME === 'PapiGames' && sessionStorage.gbBonusCode) {
      acc['bonusCode'] = sessionStorage.gbBonusCode;
    }
    return acc;
  }, {});
};

const makeSignInPayload = (data: {
  username?: string;
  password: string;
  email?: string;
  cpf?: number;
  authClientAppId?: string;
}): SignInPayload => {
  const skinId = getSkinId();
  const { username, password, email, cpf, authClientAppId } = data;
  const loginData: SignInPayload = {
    password: password?.trim(),
    skinId: skinId,
  };
  if (authClientAppId) {
    loginData.authClientAppId = authClientAppId;
  }
  if (username) {
    loginData.username = username.trim();
  } else if (email) {
    loginData.email = email.trim();
    loginData.loginKey = 'email';
  } else if (cpf) {
    loginData.cpf = String(cpf);
    loginData.loginKey = 'cpf';
  }
  return loginData;
};

const getTags = (tags: any): any => {
  return Object.keys(tags).map((tagName: string) => {
    const tagGames = tags[tagName].map((product: any) => {
      return product;
    });
    return {
      tagName,
      tagGames,
    };
  });
};

const configureRedirectWindow = (transactionStatus: any, redirectWindow: any, selectedPayment: PaymentMethod): void => {
  if (redirectWindow) {
    if (transactionStatus?.redirect) {
      redirectWindow.location = transactionStatus.redirect;
      redirectWindow.focus();
    }

    if (transactionStatus.closeRedirectWindow) {
      redirectWindow.close();
    }
  }

  if (transactionStatus.redirect && selectedPayment?.method === 'coinsPaid') {
    window.location = transactionStatus.redirect;
  }

  if (transactionStatus.redirectUrl) {
    if (redirectWindow) {
      transactionStatus.closeRedirectWindow = false;
      redirectWindow.location = transactionStatus.redirectUrl;
      redirectWindow.focus();
    } else {
      window?.open(transactionStatus.redirectUrl, `${isMobile ? '_self' : '_blank'}`);
    }
  }
};

const convertBankListIntoSelectOption = (bankList: BankListItem[], key: string, value: string): SelectOptionType[] => {
  return bankList?.map((bank: any) => {
    return { label: bank[key], value: bank[value] };
  });
};

const convertIntoSelectOption = (list: any[], key?: string, value?: string): SelectOptionType[] => {
  return list?.map((l: any) => {
    if (key && value) {
      return { label: l[key], value: l[value] };
    } else if (typeof l === 'string') {
      return { label: l, value: l };
    } else {
      return { label: l.name, value: l.id };
    }
  });
};

export const convertMsToTime = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const seconds = totalSeconds % 60;
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const hours = Math.floor(totalSeconds / 3600);

  let result = '';

  if (hours > 0) {
    result += hours + ' hours ';
  }
  if (minutes > 0) {
    result += minutes + ' m ';
  }
  if (seconds > 0 || result === '') {
    result += seconds + ' s';
  }

  return result.trim();
};

const getTransactionSuccessNotification = (response: any, data: any): any => {
  let notification = null;
  switch (response?.result?.responseHandling?.type) {
    case 'QRCode':
      notification = response.result;
      break;
    case 'redirectionLink':
      if (data.depositUseHomeUrl) {
        notification = 'willRedirect';
      }
      if (data.transactionType.toLowerCase() !== 'deposit') {
        notification = `successfullTransactionRequestMessages.${data.transactionType.toLowerCase()}`;
      }
      break;
    case 'responseMessage':
      notification = `successfullTransactionRequestMessages.${data.transactionType.toLowerCase()}`;
    default:
      break;
  }
  return notification;
};

const getTransactionErrorNotification = (e: any, data: any): void => {
  const transactionFailed = e.response?.data?.message.key || e.response?.message?.key || e.message;

  let notification = null;

  switch (transactionFailed) {
    case 'movement_limit':
      (notification = `errorTransactionRequest.${data.transactionType.toLowerCase()}`),
        {
          amount: e?.response?.data.message.params[1].amount,
          limit: e?.response?.data.message.params[1].limit / 10000,
        };
      break;
    case 'transaction_not_allowed':
      notification = `transactionNotAllowed.${data.transactionType.toLowerCase()}`;
      break;
    case 'unprocessable_entity':
      notification = `unprocessable_entity`;
      break;
    case 'invalid_numeric':
      notification = `invalidNumeric.${data.transactionType.toLowerCase()}`;
      break;
    case 'withdraw_temp_blocked':
      notification = transactionFailed;
      break;
    case 'invalid_input':
      notification = 'invalid_input.amount';
      break;
    case 'pending_withdraw':
      notification = 'pending_withdraw';
      break;
    case 'withdraw_interval':
      (notification = 'withdraw_interval'),
        {
          interval: convertMsToTime(e.response?.data?.details.intervalMs),
          time: moment(e.response?.data?.message?.details?.availableAt).format('DD/MM | HH:mm'),
        };
      break;
    default:
      notification = transactionFailed;
      break;
  }

  return notification;
};

const getDeviceName = (device: number): string => {
  if (device === 0) return 'desktop';
  if (device === 1) return 'mobile';
  return '';
};

const getTransactionItem = (
  header: string,
  selectedType: string,
  f: any,
  statusClasses: any,
  winLossClasses: any,
  cn: any
): JSX.Element => {
  let html;

  switch (header) {
    case 'dateUpdated':
    case 'lastSpinDate':
      html = <span key={header}>{moment(f[header]).format('DD MMM YYYY, hh:mm:ss') ?? '-'}</span>;
      break;

    case 'details':
      if (f.subType === 'bonus') {
        html = (
          <div key={header} className={cn('detail_container__item', selectedType)}>
            <span>ID: {f.transactionId}</span>
          </div>
        );
      } else {
        html = (
          <div key={header} className={cn('detail_container__item', selectedType)}>
            <span>{f?.paymentMethod?.name}</span>
            <span>ID: {f.transactionId}</span>
          </div>
        );
      }
      break;
    case 'status':
      html = (
        <span key={header} className={cn('table_container__item')}>
          <div className={statusClasses(f)} />
          {f[header]}
        </span>
      );
      break;
    case 'winLoss':
      html = (
        <div key={header} className={cn('winLoss_container__item', selectedType)}>
          <div className={winLossClasses(f[header])} />
          <Typography variant="h6">{formatCurrency(f[header], f['currency'])}</Typography>
        </div>
      );
      break;
    case 'gameName':
      html = <span key={header}>{f['tournamentName'] ?? f['gameName']}</span>;
      break;
    case 'game_details':
      html = <span key={header}>{f['details']}</span>;
      break;
    case 'balanceBeforeAfter':
      const beforeWallet = f?.walletsBefore?.[0];
      const afterWallet = f?.walletsAfter?.[0];
      html = (
        <span key={header} className={cn('table_container__item balanceAfBf', selectedType)}>
          {formatCurrency(beforeWallet?.balance, beforeWallet?.currency)}
          <p>/</p>
          {formatCurrency(afterWallet?.balance, afterWallet?.currency)}
        </span>
      );
      break;
    case 'device':
      const deviceName = getDeviceName(f?.device);
      const deviceInfo = f?.deviceInfo;
      html = (
        <span key={header} className={cn('table_container__item', selectedType)}>
          <Tooltip
            data={
              <>
                <div>{deviceName} </div>
                <div>{deviceInfo}</div>
              </>
            }
            position="left"
            className="deviceInfo"
          >
            <SvgIcon src={icons.info} />
          </Tooltip>
        </span>
      );
      break;
    case 'amount':
    case 'numberOfBets':
      const amount = header === 'numberOfBets' ? 'amount' : header;
      html = (
        <span key={header} className={cn('table_container__item', selectedType)}>
          {formatCurrency(f[amount], f['currency'], undefined, f.type === 'buyin')}
        </span>
      );
      break;
    default:
      html = <span key={header}>{f[header] ?? '-'}</span>;
  }
  return html;
};

const transformBanners = (data: any): any => {
  return data.reduce((acc: any, cur: any) => {
    const key = cur.key;
    if (acc[key]) {
      acc[key].push(cur);
    } else {
      acc[key] = [cur];
    }
    return acc;
  }, {});
};

const replaceImgPath = (imgPath: string, width?: string, height?: string): string => {
  if (imgPath.includes('.gif')) {
    return imgPath;
  } else if (imgPath.includes('media.draftplaza.com')) {
    let newPath = `${imgPath.replace('media.draftplaza.com', 'media-strapi.imgix.net')}?auto=format&fm=avif&q=75`;
    if (width) {
      newPath += `&w=${width.replace('px', '')}`;
    }
    if (height) {
      newPath += `&h=${height.replace('px', '')}`;
    }
    return newPath;
  } else if (imgPath.includes('media.ttfileserver.com')) {
    let newPath = `${imgPath.replace(
      'media.ttfileserver.com',
      'experimental-ttl.imgix.net/'
    )}?auto=format&fm=avif&q=75`;
    if (width) {
      newPath += `&w=${width.replace('px', '')}`;
    }
    if (height) {
      newPath += `&h=${height.replace('px', '')}`;
    }
    return newPath;
  }
  return imgPath;
};

const makePath = (str: string): string => str.replaceAll(' ', '-');

const formatDate = (date: Date, format = 'DD.MM.YYYY HH:mm:ss'): string => {
  return moment(date).format(format);
};

export {
  configureRedirectWindow,
  convertBankListIntoSelectOption,
  convertIntoSelectOption,
  formatDate,
  getTags,
  getTransactionErrorNotification,
  getTransactionItem,
  getTransactionSuccessNotification,
  makePath,
  makeSignInPayload,
  makeSignUpPayload,
  replaceImgPath,
  transformBanners,
};
