import cn from 'classnames';
import { ChangeEvent, FC, FocusEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { disableInputNumberSymbols } from 'src/helpers/utils';
import { icons } from '../../../configs/icons';
import { Typography } from '../Typography';
import { SvgIcon } from './../SvgIcon';
import styles from './styles.module.scss';

export type Props = {
  placeholder?: string;
  rightBlock?: ReactNode;
  className?: string;
  errorClassName?: string;
  pattern?: RegExp;
  onChange: (e: any) => void;
  onFocus?: (e?: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  keyDown?: (e: any) => void;
  isLightInp?: boolean;
  isTransparent?: boolean;
  height?: string;
  width?: string;
  error?: string | boolean;
  type?: 'password' | 'text' | 'number';
  hint?: string;
  value: string | number;
  name: string;
  id?: string;
  label?: string;
  size?: 'lg' | 'md' | 'sm' | 'auto';
  disabled?: boolean;
  readOnly?: boolean;
  variant?: 'withIcon' | 'dropdown' | 'simple' | 'currency';
  currency?: string;
  currencyClassName?: string;
  resetInputValue?: (name: string) => void;
  icon?: string;
  min?: number;
  iconPosition?: 'left' | 'right';
  inputHeight?: number;
  clearable?: boolean;
  required?: boolean;
  onChangeCB?: () => void;
  regexp?: string;
  forceErrorMessage?: string;
  checkRegExp?: boolean;
  checkPattern?: boolean;
  disableNumberSymbols?: boolean;
  isLoading?: boolean;
  minValue?: number;
  tabIndex?: number;
  ref?: any;
};

const iconPositionHashMap = {
  left: '0 16px 0 47px',
  right: '0 70px 0 16px',
};

export const Input: FC<Props> = ({
  placeholder,
  rightBlock,
  className,
  onChange,
  pattern,
  onFocus,
  onBlur,
  keyDown,
  height = 'md',
  width,
  error,
  errorClassName,
  value,
  name,
  type = 'text',
  id = 'numberInput',
  label,
  size = 'auto',
  disabled = false,
  readOnly = false,
  variant = 'simple',
  clearable,
  currency,
  currencyClassName,
  resetInputValue,
  icon,
  iconPosition = 'right',
  hint,
  inputHeight = 52,
  onChangeCB,
  required,
  regexp,
  checkRegExp,
  checkPattern,
  disableNumberSymbols,
  isLoading,
  minValue,
  tabIndex,
  isLightInp,
  isTransparent,
  ref,
  ...rest
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t }: Translation = useTranslation();

  const [inputPadding, setInputPadding] = useState<string>('0 16px');
  const [passwordVisible, setPassowrdVisible] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>('');
  const [inpError, setInpError] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);

  const inputRef = useRef(null);

  useEffect(() => {
    !!error && setHasError(true);
  }, [error]);

  const showCleanIcon = useMemo(() => !readOnly && !disabled && !!value, [disabled, readOnly, value]);

  const changeInpValue = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e);
    setInpError('');

    if (onChangeCB) {
      onChangeCB();
    }
  };

  const handleChange = (e: any): void => {
    if (e.nativeEvent.inputType === 'insertFromPaste' && e.target.name === 'parentId') {
      return;
    }

    if (pattern && checkPattern) {
      const value = e.target.value;

      if (!value.match(pattern) && value !== '') {
        setInpError(t('invalid_input_reg'));
        e.preventDefault();
      } else {
        changeInpValue(e);
      }
    } else if (regexp && checkRegExp) {
      const value = e.target.value;

      if (!value.match(new RegExp(regexp)) && value !== '') {
        setInpError(t('invalid_input_reg'));
        e.preventDefault();
      } else {
        changeInpValue(e);
      }
    } else {
      changeInpValue(e);
    }
  };

  const handleTogglePasswordVisibility = (): void => {
    setPassowrdVisible((prevState) => {
      setInputType(prevState ? 'password' : 'text');
      return !prevState;
    });
  };

  const numberInputOnWheelPreventChange = (e: any): void => {
    if (type === 'number') {
      e.target.blur();
      e.stopPropagation();
    }
  };

  const handleClick = (): void => {
    // Call the onFocus function passed from the parent
    if (onFocus) {
      onFocus();
    }

    // Set focus on the input element
    if (inputRef.current) {
      (inputRef.current as any).focus();
    }
  };

  useEffect(() => {
    if (variant !== 'simple') {
      setInputPadding(iconPositionHashMap[iconPosition]);
    }
  }, [variant, value, iconPosition]);

  useEffect(() => {
    if (clearable) {
      setInputPadding(`0px ${currency ? 110 : 35}px 0px ${iconPosition === 'left' ? 47 : 16}px`);
    }
  }, [clearable, currency, iconPosition]);

  useEffect(() => {
    setInputType(type);

    if (type === 'number') {
      const elem = document.getElementById(id);
      elem?.addEventListener('wheel', function () {
        elem.blur();
      });
    }
  }, [type]);

  return (
    <div
      className={cn(
        styles.input,
        {
          [styles[`input_size__${size}`]]: size,
          [styles[`input_rightBlock_${height}`]]: rightBlock && height,
          [styles[`input_rightBlock_${iconPosition}`]]: rightBlock && iconPosition,
          [styles.input_error]: hasError,
        },
        className
      )}
    >
      {label && label !== 'phoneNumber_label2' && (
        <Typography className={styles.input_label} color="gray-300" variant="body5" fontWeight="400">
          {t(label)} {required && <span>*</span>}
        </Typography>
      )}
      <div className={styles.input_wrapper} onClick={handleClick}>
        <input
          autoComplete="off"
          placeholder={t(placeholder)}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value ?? ''}
          min={minValue || 0}
          name={name}
          type={inputType}
          id={id}
          disabled={disabled}
          readOnly={readOnly}
          className={cn({ [styles.light]: isLightInp, [styles.transparent]: isTransparent })}
          tabIndex={tabIndex}
          style={{ padding: inputPadding }}
          {...rest}
          onKeyDown={(e) => (keyDown ? keyDown(e) : disableInputNumberSymbols(e, inputType, disableNumberSymbols))}
          onWheel={numberInputOnWheelPreventChange}
          inputMode={inputType === 'number' ? 'numeric' : 'text'}
          ref={inputRef}
        />
        {inpError && !error && (
          <Typography color={'error'} variant="body5">
            {inpError}
          </Typography>
        )}
        {icon && isLoading && (
          <div className={styles.loader_spinner_wrapper}>
            <img src="/images/loading.png" alt="Spinner" />
          </div>
        )}
      </div>
      {type === 'password' && (
        <div className={styles.iconContainer} onClick={handleTogglePasswordVisibility}>
          <SvgIcon pointer src={passwordVisible ? icons.view : icons.hide} />
        </div>
      )}
      {clearable && String(value ?? '').length > 0 && !currency && (
        <SvgIcon
          pointer
          src={icons.close}
          className={cn(styles.input_clear, { [styles.input_clear_hasLabel]: !!label })}
          onClick={() => onChange({ target: { value: '', name } })}
        />
      )}
      {(hasError || hint) && (
        <div
          style={{ position: label === 'phoneNumber_label2' ? 'absolute' : 'relative' }}
          className={cn(styles.error, errorClassName)}
        >
          <Typography color={hasError ? 'error' : 'gray-300'} variant="body5">
            {t(error)}
          </Typography>
        </div>
      )}
      <div
        className={cn({
          [styles.input__rightBlock]: true,
          [styles[`input__rightBlock_${height}`]]: height,
          [styles[`input__rightBlock_${iconPosition}`]]: iconPosition,
        })}
      >
        {showCleanIcon && (
          <div>
            {resetInputValue && (
              <SvgIcon pointer src={icons.close} color="#757B8C" onClick={() => resetInputValue(name)} />
            )}
          </div>
        )}
        {currency && (
          <div className="flexible aCenter">
            {clearable && String(value ?? '').length > 0 && (
              <SvgIcon pointer src={icons.close} onClick={() => onChange({ target: { value: '' } })} />
            )}
            <Typography color="gray-300" className={cn(styles.input_currency, currencyClassName)}>
              {currency}
            </Typography>
          </div>
        )}
        {variant === 'withIcon' && (
          <div className={cn(styles.iconContainer)}>
            {icon && <SvgIcon pointer src={icons[icon]} color="#757B8C" />}
          </div>
        )}
      </div>
    </div>
  );
};
