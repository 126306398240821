import moment from 'moment';
import 'moment/min/locales';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input } from 'src/components/common/Input';
import { getSkinId } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { validateCPFRequest } from 'src/services/authorizationApi';
import { setCPFValue, setCPF_User, setDniResult, setError, toggleActivateBtn } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import _styles from './styles.module.scss';

type IProps = {
  name: string;
  value: string;
  regexp: string;
  onChange: (e: any) => void;
  placeholder?: string;
  required: boolean;
};
const CPF_Input: FC<IProps> = (props) => {
  // Hooks
  const { t }: Translation = useTranslation();
  const { locale } = useI18n();
  const skinId = getSkinId();

  // Redux
  const _toggleActivateBtn = useConnectedAction(toggleActivateBtn);
  const _setCPF_User = useConnectedAction(setCPF_User);
  const _setErrorReq = useConnectedAction(setError);
  const _setDniResult = useConnectedAction(setDniResult);
  const _setCPFValue = useConnectedAction(setCPFValue);
  const resetSignUpError = useResetSignUpError();

  // Selectors
  const { additionalInfo } = useSelector((state: RootState) => state.user);
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  // States
  const [isLoading, setLoading] = useState<boolean>(false);
  const [validatedCPF, setValidatedCPF] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  // API Calls
  const validateCPFReq = (value: any): void => {
    setDisabled(true);
    setLoading(true);
    validateCPFRequest({ nationalId: value, skinId: skinId })
      .then((res: any) => {
        if (res.success) {
          setTimeout(() => {
            setLoading(false);
            resetSignUpError('cpf');
            _setCPFValue(value);
            _setCPF_User(res.inputFields);
            process.env.REACT_APP_WEBSITENAME === 'PapiGames' && _setDniResult(res.result.gender);
            _toggleActivateBtn(true);
            setValidatedCPF(value);
          }, 1000);
        } else {
          if (res?.message?.params?.[1]?.reason === 'underaged') {
            _setErrorReq({ ...signUpErrors, cpf: t('invalid_national_id_underaged') });
          } else {
            _setErrorReq({ ...signUpErrors, cpf: t('invalid_national_id') });
          }
          setLoading(false);
          _setCPF_User(null);
          _toggleActivateBtn(false);
        }
        setDisabled(false);
      })
      .catch(() => {
        setTimeout(() => {
          _setErrorReq({ ...signUpErrors, cpf: t('invalid_national_id') });
          setLoading(false);
          _setCPF_User(null);
          _toggleActivateBtn(false);
          setDisabled(false);
        }, 1000);
      });
  };

  // YUP Validation
  const validationSchema = Yup.object().shape({
    cpf: Yup.string().required(t('requiredField')).min(11, t('invalid_CPF_length')).max(11, t('invalid_CPF_length')),
  });

  const handleValidate = async (value: string): Promise<void> => {
    try {
      await validationSchema.validate({ cpf: value }, { abortEarly: false });
      resetSignUpError('cpf');
      if ((!additionalInfo || (additionalInfo && props.value !== validatedCPF)) && props.value) {
        resetSignUpError('cpf');
        validateCPFReq(props.value);
      } else {
        _setCPF_User(null);
        _toggleActivateBtn(false);
        _setErrorReq({ ...signUpErrors, cpf: t('national_id_used') });
      }
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setCPF_User(null);
        _toggleActivateBtn(false);
        _setErrorReq({ ...signUpErrors, cpf: error.errors[0] });
      }
    }
  };

  // Actions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (showError) {
      setShowError(false);
    }
    if (e.target.value.length !== 11) {
      handleValidate(e.target.value);
    }
    props.onChange(e);
  };

  const handleBlur = (): void => {
    setShowError(true);
    handleValidate(props.value);
  };

  // Effects
  useEffect(() => {
    const selelectedlang = localStorage.getItem('selectedLanguage') ?? locale;
    moment.locale(selelectedlang);
  }, [locale]);

  useEffect(() => {
    if (props.value?.length === 11) {
      setShowError(true);
      handleValidate(props.value);
    } else {
      _setCPF_User(null);
      _toggleActivateBtn(false);
    }
    return () => {
      _toggleActivateBtn(false);
    };
  }, [props.value]);

  return (
    <>
      <Input
        error={showError ? signUpErrors.cpf : ''}
        {...props}
        onBlur={handleBlur}
        onChange={handleOnChange}
        id={'cpf_input'}
        type="number"
        disableNumberSymbols
        className={_styles.cpf__input}
        icon={'spinner'}
        iconPosition="right"
        isLoading={isLoading}
        disabled={disabled}
      />
      <div style={{ visibility: additionalInfo ? 'visible' : 'hidden' }} className={_styles.info}>
        <div className={_styles.info_section}>
          <span className={_styles.info_label}>{t('label_name')}: </span>
          <div className={_styles.info_value}>
            {additionalInfo?.firstname} {additionalInfo?.lastname}
          </div>
        </div>
        <div className={_styles.info_section}>
          <span className={_styles.info_label}>{t('label_date')}: </span>
          <div className={_styles.info_value}>{moment(additionalInfo?.birthDate).format('DD MMM YYYY')}</div>
        </div>
      </div>
    </>
  );
};

export default CPF_Input;
