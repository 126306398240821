import cn from 'classnames';
import { useFormik } from 'formik';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { getSkinId } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { forgotPasswordRequest } from 'src/services/authorizationApi';
import { setForgotText } from 'src/store/auth-modal/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';
import { forgotPasswordValidationScheme } from '../../../../../../helpers/validationScheme';
import { IForgotRes } from '../../../../../../types/res-dto/authorization';
import { Button } from '../../../../../common/Button';
import { Input } from '../../../../../common/Input';
import _styles from './styles.module.scss';

interface IProps extends IForgotRes {
  close: () => void;
  setContent: (action: 'open' | 'close', content: 'login' | 'registration' | 'forgot' | null) => void;
}

type Form = {
  email: string;
  username: string;
  cpf?: string;
};

const ForgotPassContent: FC<IProps> = ({ close, username, email, cpf }) => {
  const { t }: Translation = useTranslation();
  const skinId = getSkinId();
  const isResetInProcess: any = useRef(null);

  const { forgotContentText } = useSelector((state: RootState) => state.authModal);

  const _openDialog = useConnectedAction(openDialog);
  const _setForgotText = useConnectedAction(setForgotText);

  const [generalError, setGeneralError] = useState<string>('');

  const handleSubmitForgot = async (): Promise<void> => {
    const res = await forgotPasswordRequest({
      email: values.email.trim().toLowerCase(),
      username: values.username.trim(),
      skinId: skinId,
    });

    if (!!res.success) {
      setGeneralError('');
      close();
      _openDialog({
        dialogType: EnumDialogsKeys.RESET_PASSWORD_SUCCESS,
        dialogProps: { email: values.email, dialogCase: 'forgotPassword' },
      });
    } else if (['document_not_found', 'unprocessable_entity'].includes(res.message?.key)) {
      setGeneralError('incorrectUsernameOrEmail');
    }
  };

  const formik = useFormik<Form>({
    validationSchema: forgotPasswordValidationScheme(),
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      email: '',
      username: '',
      cpf: '',
    },
    onSubmit: () => {
      if (process.env.REACT_APP_KYC_ENABLE) {
        const sdkInstance = (window as any)?.Legitimuz({
          token: '5cb851a5-fb77-4474-95ca-1780a5fc3bab',
          host: 'https://api.legitimuz.com',
          // onSuccess: () => {
          //   _setForgotText('');
          //   handleSubmitForgot();
          // },
          onSuccess: () => {
            setTimeout(() => {
              if (!isResetInProcess.current) {
                isResetInProcess.current = true;
                _setForgotText('');
                handleSubmitForgot();
              }
            }, 0);
            setTimeout(() => {
              isResetInProcess.current = false;
            }, 10000);
          },
        });
        sdkInstance.mount();
        sdkInstance.verifyDocument({ cpf: values.cpf });
      } else {
        handleSubmitForgot();
      }
    },
  });

  const { values, getFieldProps, handleSubmit, errors, touched } = formik;

  return (
    <>
      <p className={_styles.subText}>{forgotContentText ? t(forgotContentText) : t('forgot_pass_text')}</p>
      <p className={_styles.subText}>{t('forgot_pass_additional_text')}</p>
      <div className={_styles.forgot_password_container}>
        {Boolean(username) && (
          <Input
            {...getFieldProps('username')}
            value={values.username}
            label={'username'}
            error={(!!touched.username && !!errors.username && t(errors.username)) || ''}
          />
        )}
        {Boolean(email) && (
          <Input
            {...getFieldProps('email')}
            value={values.email}
            label={'email'}
            error={(!!touched.email && !!errors.email && t(errors.email)) || ''}
          />
        )}
        {Boolean(cpf) && (
          <Input
            {...getFieldProps('cpf')}
            value={values.cpf as any}
            label={'cpf'}
            error={(!!touched.cpf && !!errors.cpf && t(errors.cpf)) || ''}
          />
        )}
      </div>
      {generalError && (
        <Typography variant={'body3'} className={_styles.invalid_error} color="error">
          {t(generalError)}
        </Typography>
      )}
      <Button className={cn(_styles.recoverButton, 'success-filled-btn')} onClick={handleSubmit}>
        {t('button_recover')}
      </Button>
    </>
  );
};

export default ForgotPassContent;
