import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';

interface IProps {
  data?: any;
}
const KycPopup: FC<IProps> = () => {
  const { t }: Translation = useTranslation();

  const { kycStatus } = useSelector((state: RootState) => state.user);

  setBodyOverflow('unset');

  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    setBodyOverflow('set');
    _closeDialog({ dialogType: EnumDialogsKeys.KYC_POPUP });
  };

  const onOk = (): void => {
    _openAccountModal({ section: 'kyc', subSection: 'kyc', fullHeight: true });
    removeDialog();
  };

  return (
    <PopUpContainer
      isOpen={Boolean(kycStatus) || kycStatus === undefined}
      handleOK={onOk}
      hasOverlay
      title={t('kyc_image_status')}
      zIndex={250}
    />
  );
};

export default KycPopup;
